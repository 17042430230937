import { PLATFORMS } from '@hmhco/platform-helper';
import { useEffect } from 'react';
import { Authorize } from '@hmh/eds-login-js';
import logErrorWithContext from '@hmhco/client-monitoring/src/context/logErrorWithContext';
import {
  getIdentifiedPlatformClientId,
  getIdentifiedPlatformConfig,
} from '@hmhco/login-config';
import { LOGIN_METHOD_KEY } from '../constants/constants';
import { handleRedirectToSsoUri } from '../components/SSOFlow/SSOPage';
import { removeLocalStorageValue } from '../storageHelpers/localStorageAccessors';
import { getSignedInUrl } from '../util/getSignedInUrl';

const useHnmSSORedirect = platform => {
  useEffect(() => {
    if (platform === PLATFORMS.FLIGHT) {
      const search = new URLSearchParams(window.location.search);
      if (search.has('iss') && !search.has('client_id')) {
        const authorize = new Authorize(
          getIdentifiedPlatformClientId(),
          search.get('iss'),
          getIdentifiedPlatformConfig().edsInitUrl,
          { targetLinkUri: getSignedInUrl() },
        );

        authorize
          .getThirdPartyLoginUri()
          .then(handleRedirectToSsoUri)
          .catch(error => {
            logErrorWithContext(
              'Flight Rostered Login app - Error Retrieving Third Party Login URI',
              [{ key: 'errorMessage', value: error }],
            );
            window.location.assign(getIdentifiedPlatformConfig().loginUrl);
          });

        return;
      }
    }
    removeLocalStorageValue(LOGIN_METHOD_KEY);
  }, [platform]);
};

export default useHnmSSORedirect;
