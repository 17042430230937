/* eslint-disable import/prefer-default-export */
import { getCurrentEnv } from '@hmhco/amp-core-env';
import { ENVIRONMENTS } from '../../../../../environments/globalConstants';
import { identifyPlatform } from './identifyPlatform';
import { CLIENT_IDS, LOCAL_CLIENT_IDS } from '../constants';

/**
 * ! SHOULD BE USED ONLY BY HCP LOGIN UI
 * ! CONSULT WITH ENABLED TEAM BEFORE USING IT ANYWHERE ELSE
 */
export function getIdentifiedPlatformClientId() {
  const platform = identifyPlatform();

  if (getCurrentEnv() === ENVIRONMENTS.local) {
    return LOCAL_CLIENT_IDS[platform];
  }

  const clientId = CLIENT_IDS[platform];

  if (!clientId) {
    throw Error(`Client ID not found for platform ${platform}`);
  }

  return clientId;
}
