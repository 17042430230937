import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { useSelector } from 'react-redux';
import useStyles from './OktaFormSkeleton.styles';
import { getOktaReady } from '../../store/selectors/TenantPickerState';

const OktaForm = () => {
  const { classes } = useStyles();
  const oktaReady = useSelector(getOktaReady);

  if (oktaReady) return null;

  return (
    <div className={classes.container} data-testid="oktaFormSkeleton">
      <Skeleton
        className={classes.label}
        variant="rectangular"
        height="15px"
        width="15%"
      />
      <Skeleton
        className={classes.field}
        variant="rectangular"
        height="40px"
        width="100%"
      />
      <Skeleton
        className={classes.label}
        variant="rectangular"
        height="15px"
        width="15%"
      />
      <Skeleton
        className={classes.field}
        variant="rectangular"
        height="40px"
        width="100%"
      />
      <div className={classes.alignRight}>
        <Skeleton
          className={classes.button}
          variant="rectangular"
          height="15px"
          width="20%"
        />
      </div>
      <div className={classes.spaceBetween}>
        <Skeleton
          className={classes.button}
          variant="rectangular"
          height="35px"
          width="20%"
        />
        <Skeleton
          className={classes.button}
          variant="rectangular"
          height="35px"
          width="20%"
        />
      </div>
    </div>
  );
};

export default OktaForm;
