/* eslint-disable import/prefer-default-export */
import {
  getIdentifiedPlatformConfig,
  LEGACY_ED_CLIENT_ID,
} from '@hmhco/login-config';
import { SCOPE, RESPONSE_TYPE } from '../constants/constants';
import { tryDecodeUrlParam } from '../util/tryDecodeUrlParam';

export function buildAuthenticationUrl({
  pid,
  nonce,
  stateParam,
  resourceUrl,
}) {
  if (!pid || !nonce) {
    throw new Error('buildAuthenticationUrl requires a pid and a nonce');
  }
  const config = getIdentifiedPlatformConfig();

  const searchParams = new URLSearchParams(
    `?connection=${pid}&client_id=${LEGACY_ED_CLIENT_ID}&scope=${SCOPE}&response_type=${RESPONSE_TYPE}&nonce=${nonce}&redirect_uri=${encodeURIComponent(
      config.loggedInUrl,
    )}&state={}`,
  );
  if (stateParam) {
    const decodedState = tryDecodeUrlParam(stateParam, 'state');
    if (decodedState) {
      searchParams.set('state', decodedState);
    }
  }
  if (resourceUrl) {
    const decodedResourceUrl = tryDecodeUrlParam(resourceUrl, 'resource_url');
    if (decodedResourceUrl) {
      searchParams.set('resource_url', decodedResourceUrl);
    }
  }
  return `${config.susAuth}/authorize?${searchParams.toString()}`;
}
