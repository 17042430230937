import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { initializeOktaForm } from './initializeOktaForm';

const useInitializeOktaForm = (isVisible, oktaInfo, widgetRef) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  useEffect(
    () =>
      initializeOktaForm(
        isVisible,
        oktaInfo,
        widgetRef,
        formatMessage,
        dispatch,
      ),
    [isVisible, oktaInfo, widgetRef, formatMessage, dispatch],
  );
};

export default useInitializeOktaForm;
