const currentPort =
  (typeof window !== 'undefined' && window?.location?.port) || '9002';

const devCertHnmConfig = {
  name: 'devCert',
  contentShareAppUrl: 'https://platform-content-share.cert.heinemann.com/',
  edsLogoutUrl: 'https://api.cert.heinemann.com/login/logout',
  contentProvider: `https://local.hmhone.hmhco.com:${currentPort}/ui/#/contentProvider/`,
  loginUrl: `https://local.hmhone.hmhco.com:${currentPort}/ui/login/`,
  loggedInUrl: `https://local.hmhone.hmhco.com:${currentPort}/ui/logged-in/`,
  loggedOutUrl: `https://local.hmhone.hmhco.com:${currentPort}/ui/login/logged-out/`,
  helpUrl: 'http://downloads.hmlt.hmco.com/Help/Flight/LogIn/',
  privacyPolicyUrl:
    'https://www.heinemann.com/privacy/?utm_medium=shorturl&utm_source=privacy',
  termsAndConditionsUrl:
    'https://www.heinemann.com/platforms-terms-of-use/?utm_medium=shorturl&utm_source=platform-terms',
  forgotPasswordUrlPath: '/ui/login/forgot-password/',
  requestSigninDetailsUrlPath: '/ui/login/request-signin-details/',
  learnosityAssessementPreview: `https://local.hmhone.hmhco.com:${currentPort}/ui/#/assessment-view/preview`,
  edsLoginLandingPage: `https://local.hmhone.hmhco.com:${currentPort}/ui/login/landing-page`,
  edsPostLogoutRedirectUrl: `https://local.hmhone.hmhco.com:${currentPort}/`,
  flightRosteredLoginUrl: `https://local.hmhone.hmhco.com:${currentPort}/ui/login/`,
  edsLoginClientId: '2cc7842e-ed1b-401e-b203-c2756a26d795',
  edsLoginUrl: 'https://api.cert.heinemann.com/login/login/initializeUri',
  edsLoginIssuer: 'https://platform-cert.authorization.heinemann.com',
  edsGraphUrl: 'https://api.cert.heinemann.com/graphql',
  edsLtiUrl: 'https://api.cert.heinemann.com/lti/lti',
  edLtiUrl: 'https://api.cert.hmhco.com/lti-platform',
  rceProtocolHost: 'https://cert.hmhco.com',
  planktonBaseUrl: 'https://d16qzots3do3wk.cloudfront.net/',
  isPlanktonPreview: false,
  edsContentShareClientId: '2432c4e2-921a-460a-b938-7c44fc7d516e',
  edsContentShareInitializeUrl:
    'https://api.cert.heinemann.com/lti/lti/launch/share-link',
  edsContentShareAuthorizeUrl:
    'https://api.cert.heinemann.com/lti/lti/authorize',
  edsContentShareTokenUrl: 'https://api.cert.heinemann.com/lti/lti/token',
  licensesRemoteEntrypoint: 'http://localhost:9999',
  restUdsApi: 'https://api.cert.hmhco.com/uds/',
  assessmentsK8: 'https://app01.int.tsg.hmhco.com/assessment',
};

module.exports = devCertHnmConfig;
