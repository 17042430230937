import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'Marketing' })(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    padding: 'var(--ebl-s4)',
    backgroundColor: theme.palette.ebl.marketingBackground,
  },
  nwea: {},
  heinemann: {
    position: 'relative',
    overflow: 'hidden',
  },
  hmh: {
    height: '100%',
  },
  image: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  nweaImage: {
    height: '100%',
    width: 'min(min(calc((100vw - 535px) / 4 * 3), 100vh), 535px)',
    display: 'flex',
    justifyContent: 'center',
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& svg': {
      height: '100%',
      width: '100%',
    },
  },
  heinemannImage: {
    width: 'calc(100% - 3rem)',
    translate: '0 4rem',
    scale: '1.2',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'top center',
  },
  hmhImage: {
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& svg': {
      maxHeight: 'calc(100vh - 3rem)',
      height: '100%',
      width: '100%',
    },
  },
}));
