import { getUserEntitlements } from '@hnm/session-context';
import { FlightBundle } from '@hnm/types';

const sortProductEntitlement = list => {
  return list
    .map(prod => {
      return {
        ...prod,
        productName: `${prod.displayTitle} ${prod?.gradeList}`,
      };
    })
    .sort((a, b) => a.productName.localeCompare(b.productName));
};

const getAllProductEntitlementList = () => {
  const productEntitlementList: FlightBundle[] = getUserEntitlements() ?? [];
  return sortProductEntitlement(productEntitlementList);
};

export default getAllProductEntitlementList;
