import React from 'react';
import { TextField } from '@mui/material';
import { func, string } from 'prop-types';
import { useIntl } from 'react-intl';
import useStyles from './Input.styles';
import { AUTO_COMPLETE } from '../../../../constants/constants';

const Username = props => {
  const { handleUsernameChange, errorMessageId } = props;
  const { formatMessage } = useIntl();
  const usernameLabel = formatMessage({ id: 'login.form.usernameLabel' });
  const message = errorMessageId ? formatMessage({ id: errorMessageId }) : '';
  const { classes } = useStyles();

  return (
    <TextField
      error={Boolean(errorMessageId)}
      onChange={handleUsernameChange}
      helperText={message}
      inputProps={{ className: classes.input }}
      FormHelperTextProps={{
        'aria-live': 'polite',
      }}
      label={usernameLabel}
      id="new-password-username"
      name="new-password-username"
      required
      autoComplete={AUTO_COMPLETE.USERNAME}
    />
  );
};

Username.propTypes = {
  handleUsernameChange: func,
  errorMessageId: string,
};

export default Username;
