/* eslint-disable import/prefer-default-export */
import {
  PLATFORMS,
  DEFAULT_CONFIGURATIONS,
} from '../../../../../environments/globalConstants';
import { getUiPlatform } from '../../../../../environments/getUiPlatform';

/**
 * ! SHOULD BE USED ONLY BY HCP LOGIN UI
 * ! CONSULT WITH ENABLED TEAM BEFORE USING IT ANYWHERE ELSE
 *
 * This function is used to identify the platform based on the URL.
 * It is used to determine the platform for UI code deployed to a single location
 * that serves multiple URLs/platforms (such as login)
 * so we're keeping it for Login purposes
 * and if we want to switch to this pattern (single deploy, multiple hostnames) for the platform
 *
 * On environments, it checks the URL and returns ED, FLIGHT or NWEA
 * On local it uses the hardcoded configuration (ui platform)
 *
 */
export function identifyPlatform() {
  const identifiedConfiguration = Object.values(
    DEFAULT_CONFIGURATIONS,
  ).find(defaultConfiguration =>
    window.location.host.includes(defaultConfiguration.predicate),
  );

  let identifiedPlatform = identifiedConfiguration?.name;

  if (!identifiedPlatform) {
    console.error('No platform found default to HMH Ed');
    identifiedPlatform = PLATFORMS.ED;
  }

  const isLocal = window.location.host.includes('local');

  if (isLocal) {
    return getUiPlatform() || identifiedPlatform;
  }

  return identifiedPlatform;
}
