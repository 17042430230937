/* eslint-disable import/prefer-default-export */

export const LOGIN_METHOD_KEY = 'com.hmhco.login.method';
export const LOGIN_METHODS = {
  SSO: 'sso',
};
export const LOGGED_OUT_PATH = 'logged-out/';
export function getLoginMethod() {
  try {
    return localStorage.getItem(LOGIN_METHOD_KEY);
  } catch {
    return null;
  }
}
