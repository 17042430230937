import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentOrg,
  getRenderInternationalForm,
} from '../../store/selectors/CountryStateOrg';
import useStyles from './OktaForm.styles';
import {
  getCachedOktaWidgetConfig,
  getLoginFlow,
  getLoginStep,
} from '../../store/selectors/TenantPickerState';
import { setLoginStep } from '../../store/actions/TenantPickerActions';
import useInitializeOktaForm from './useInitializeOktaForm/useInitializeOktaForm';
import SelectedOrganization from '../TenantPicker/SelectedOrganization/SelectedOrganization';
import { LOGIN_FLOWS } from '../../constants/constants';
import OktaFormSkeleton from '../OktaFormSkeleton/OktaFormSkeleton';

const OktaForm = () => {
  const { classes } = useStyles();
  const currentOrg = useSelector(getCurrentOrg);
  const isInternational = useSelector(getRenderInternationalForm);
  const dispatch = useDispatch();
  const widgetRef = useRef();
  const oktaInfo = useSelector(getCachedOktaWidgetConfig);
  const challenge = sessionStorage.getItem('challenge');
  const loginStep = useSelector(getLoginStep);
  const loginFlow = useSelector(getLoginFlow);

  const newOktaConfig = {
    ...oktaInfo,
    ...{
      issuer: `${oktaInfo.issuer}`,
      codeChallenge: challenge,
      codeChallengeMethod: 'S256',
      useInteractionCodeFlow: true,
    },
  };

  useInitializeOktaForm(
    loginStep !== 1 || loginFlow === LOGIN_FLOWS.OKTA,
    newOktaConfig,
    widgetRef,
  );

  const handleChangeClick = () => {
    dispatch(setLoginStep(1));
  };

  if (!currentOrg?.name) {
    return null;
  }

  if (loginStep === 1 || loginFlow !== LOGIN_FLOWS.OKTA) {
    return null;
  }

  return (
    <>
      <SelectedOrganization
        organizationName={currentOrg.name}
        isInternational={isInternational}
        onClick={handleChangeClick}
      />
      <OktaFormSkeleton />
      <div>
        <div id="signin-widget" ref={widgetRef} className={classes.widget} />
      </div>
    </>
  );
};

export default OktaForm;
