import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'EdEarlyAccessPage' })(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  errorContinueButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  verifyButtonStyle: {
    paddingTop: 'var(--ebl-s2)',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
  },
  verifyButton: {
    display: 'flex',
    height: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    margin: '0',
  },
  districtNamePlaceholder: {
    padding: 'var(--ebl-s2) var(--ebl-s2) var(--ebl-s2) var(--ebl-s3)',
    marginBottom: 'var(--ebl-s2)',
  },
  emailTextField: {
    flexGrow: 1,
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-20px',
    },
  },
  emailInputContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 'var(--ebl-s3)',
    marginBottom: 'var(--ebl-s4)',
    minHeight: '80px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
      minHeight: '100px',
    },
  },
  subtitleText: {
    marginBottom: 'var(--ebl-s3)',
  },
  districtComponent: {
    marginBottom: 'var(--ebl-s2)',
  },
}));
