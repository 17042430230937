import { getIdentifiedPlatformConfig } from '@hmhco/login-config';
import { getSignedInUrl } from '../util/getSignedInUrl';

function buildAuthCallback(code, state, codeVerifier) {
  const params = new URLSearchParams();
  params.append('state', state);
  params.append('interaction_code', code);
  params.append('redirect_uri', getSignedInUrl());
  params.append('code_verifier', codeVerifier);

  return `${
    getIdentifiedPlatformConfig().edsAuthorizationCode
  }?${params.toString()}`;
}

export default buildAuthCallback;
