import { Route, Switch } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import { PlatformProvider, PLATFORMS } from '@hmhco/platform-helper';
import SSOFlow from './SSOFlow/SSOPage';
import ForgotPasswordPage from './TenantPicker/Pages/ForgotPasswordPage/ForgotPasswordPage';
import RequestSignInDetailsPage from './TenantPicker/Pages/RequestSignInDetailsPage/RequestSignInDetailsPage';
import NewPasswordPage from './TenantPicker/Pages/NewPasswordPage/NewPasswordPage';
import RegisterUserPage from './TenantPicker/Pages/RegisterUserPage/RegisterUserPage';
import TenantPickerPage from './TenantPicker/TenantPickerPage';
import Error from './Error/Error';
import LoggedOutPage from './TenantPicker/Pages/LoggedOutPage/LoggedOutPage';
import EdEarlyAccessPage from './TenantPicker/Pages/EdEarlyAccessPage/EdEarlyAccessPage';

const TenantPickerRoutes = ({ url, configuration }) => {
  const path = url === '/' ? url : `${url}/`;

  return (
    <PlatformProvider platform={configuration}>
      <Switch>
        <Route path={`${path}error`}>
          <Error />
        </Route>
        <Route path={`${path}ed-early-access`}>
          <EdEarlyAccessPage />
        </Route>
        <Route path={`${path}logged-out`}>
          <LoggedOutPage />
        </Route>
        <Route path={`${path}sso`}>
          <SSOFlow />
        </Route>
        <Route path={`${path}forgot-password`}>
          <ForgotPasswordPage />
        </Route>
        <Route path={`${path}request-signin-details`}>
          <RequestSignInDetailsPage />
        </Route>
        <Route path={`${path}new-password`}>
          <NewPasswordPage />
        </Route>
        <Route path={`${path}register`}>
          <RegisterUserPage />
        </Route>
        <Route path={url}>
          <TenantPickerPage />
        </Route>
      </Switch>
    </PlatformProvider>
  );
};

TenantPickerRoutes.propTypes = {
  url: PropTypes.string.isRequired,
  configuration: PropTypes.string,
};

TenantPickerRoutes.defaultProps = {
  configuration: PLATFORMS.ED,
};

export default TenantPickerRoutes;
