/* eslint-disable import/prefer-default-export */

import { getConfigForCurrentEnv } from '@hmhco/amp-core-env';
import { identifyPlatform } from './identifyPlatform';

/**
 * ! SHOULD BE USED ONLY BY HCP LOGIN UI
 * ! CONSULT WITH ENABLED TEAM BEFORE USING IT ANYWHERE ELSE
 */
export function getIdentifiedPlatformConfig() {
  return getConfigForCurrentEnv(identifyPlatform());
}
