import {
  ENVIRONMENTS,
  PLATFORMS,
} from '../../../../environments/globalConstants';

import localNweaConfig from '../../../../config/appConfigs/nwea/local';
import localHnmConfig from '../../../../config/appConfigs/hnm/local';
import localSupportToolConfig from '../../../../config/appConfigs/supportTool/local';

import devEdConfig from '../../../../config/appConfigs/ed/dev';
import devHnmConfig from '../../../../config/appConfigs/hnm/dev';
import devSupportToolConfig from '../../../../config/appConfigs/supportTool/dev';

import intEdConfig from '../../../../config/appConfigs/ed/int';
import intHnmConfig from '../../../../config/appConfigs/hnm/int';
import intSupportToolConfig from '../../../../config/appConfigs/supportTool/int';
import intNweaConfig from '../../../../config/appConfigs/nwea/int';

import certEdConfig from '../../../../config/appConfigs/ed/cert';
import certHnmConfig from '../../../../config/appConfigs/hnm/cert';
import certSupportToolConfig from '../../../../config/appConfigs/supportTool/cert';
import certNweaConfig from '../../../../config/appConfigs/nwea/cert';

import devCertSupportToolConfig from '../../../../config/appConfigs/supportTool/devCert';
import devCertHnmConfig from '../../../../config/appConfigs/hnm/devCert';

import prodEdConfig from '../../../../config/appConfigs/ed/prod';
import prodHnmConfig from '../../../../config/appConfigs/hnm/prod';
import prodSupportToolConfig from '../../../../config/appConfigs/supportTool/prod';
import prodNweaConfig from '../../../../config/appConfigs/nwea/prod';

export default {
  [ENVIRONMENTS.local]: {
    [PLATFORMS.ED]: devEdConfig,
    [PLATFORMS.FLIGHT]: localHnmConfig,
    [PLATFORMS.FLIGHT_CONTENT_SHARE]: localHnmConfig,
    [PLATFORMS.SUPPORT_TOOL]: {
      // from a `@hmhco/signed-in` perspective, SUPPORT_TOOL only exists as a PLATFORM on local/devCert
      // on environments, the `hostname` decides the platform for `signed-in`.
      ...localSupportToolConfig,
      EDSLoginUrl: devEdConfig.EDSLoginUrl,
      ampLogin: devEdConfig.ampLogin,
      liftRoot: devEdConfig.liftRoot,
      loginUrl: devEdConfig.loginUrl,
      loggedInUrl: devEdConfig.loggedInUrl,
      loggedOutUrl: devEdConfig.loggedOutUrl,
    },
    [PLATFORMS.NWEA]: localNweaConfig,
  },
  [ENVIRONMENTS.dev]: {
    [PLATFORMS.ED]: devEdConfig,
    [PLATFORMS.FLIGHT]: devHnmConfig,
    [PLATFORMS.FLIGHT_CONTENT_SHARE]: devHnmConfig,
    [PLATFORMS.SUPPORT_TOOL]: devSupportToolConfig,
  },
  [ENVIRONMENTS.int]: {
    [PLATFORMS.ED]: intEdConfig,
    [PLATFORMS.FLIGHT]: intHnmConfig,
    [PLATFORMS.FLIGHT_CONTENT_SHARE]: intHnmConfig,
    [PLATFORMS.SUPPORT_TOOL]: intSupportToolConfig,
    [PLATFORMS.NWEA]: intNweaConfig,
  },
  [ENVIRONMENTS.devCert]: {
    [PLATFORMS.ED]: {
      ...certEdConfig,
      name: 'devCert',
      ampContent: devEdConfig.ampContent,
      liftRoot: devEdConfig.liftRoot,
      featureFlagURL: devEdConfig.featureFlagURL,
      ampSignedInURL: devEdConfig.ampSignedInURL,
      loginUrl: devEdConfig.loginUrl,
      loggedInUrl: devEdConfig.loggedInUrl,
      loggedOutUrl: devEdConfig.loggedOutUrl,
    },
    [PLATFORMS.FLIGHT]: devCertHnmConfig,
    [PLATFORMS.FLIGHT_CONTENT_SHARE]: devCertHnmConfig,
    [PLATFORMS.SUPPORT_TOOL]: devCertSupportToolConfig,
  },
  [ENVIRONMENTS.cert]: {
    [PLATFORMS.ED]: certEdConfig,
    [PLATFORMS.FLIGHT]: certHnmConfig,
    [PLATFORMS.FLIGHT_CONTENT_SHARE]: certHnmConfig,
    [PLATFORMS.SUPPORT_TOOL]: certSupportToolConfig,
    [PLATFORMS.NWEA]: certNweaConfig,
  },
  [ENVIRONMENTS.prod]: {
    [PLATFORMS.ED]: prodEdConfig,
    [PLATFORMS.FLIGHT]: prodHnmConfig,
    [PLATFORMS.FLIGHT_CONTENT_SHARE]: prodHnmConfig,
    [PLATFORMS.SUPPORT_TOOL]: prodSupportToolConfig,
    [PLATFORMS.NWEA]: prodNweaConfig,
  },
};
