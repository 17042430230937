const currentPort =
  (typeof window !== 'undefined' && window?.location?.port) || '9002';

const localSupportToolConfig = {
  app: 'HCP Support App',
  name: 'local',
  localLoginLandingPage: 'https://local.hmhone.hmhco.com:9002/',
  edsLoginLandingPage: `https://local.hmhone.hmhco.com:${currentPort}/ui/signed-in/`,
  edsLogoutUrl: 'https://app01.int.tsg.hmhco.com/edcore/eds-login/logout',
  edsPostLogoutRedirectUrl:
    'https://app01.int.tsg.hmhco.com/hcp-support-tool-web/',
  edsLoginClientId: '390B45B2-F26E-4B98-8088-5972803A607D',
  edsLoginIssuer: 'hmh-int-d7d2cc15-2530-48ed-ae0e-0024953cefb1',
  edsLoginUrl:
    'https://app01.int.tsg.hmhco.com/edcore/eds-login/login/initializeUri',
  edsServiceUrl: 'https://app01.int.tsg.hmhco.com/edcore/eds-core-app',
  appEndPointUrl: 'https://app01.int.tsg.hmhco.com/hcp-support-tool/',
  emailTokenURL:
    'https://hmh-identity-provider.int.br.hmheng.io/user/signupToken',
  commonIdsUrl: 'https://api.int.br.internal/ids-grid-api/ids/',
  edsGraphUrl:
    'https://app01.int.tsg.hmhco.com/edcore/hmheng-eds-api-gateway/graphql',
  idmUserApiUrl: 'https://api.int.br.internal/idm-user-api/',
  idmConfigUrl: 'https://config-idm.int.br.hmheng.io',
  hmhIdentityProvider: 'https://hmh-identity-provider.int.br.hmheng.io',
  edcoreGatewayUrl: 'https://hapi.int.hmhco.com/edcore/hmheng-eds-api-gateway/',
};

module.exports = localSupportToolConfig;
