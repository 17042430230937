import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import useBootstrapOrgInfo from '../../../../hooks/bootstrapForgotPasswordOrgInfo.hook';
import RequestSignInDetailsHeading from './RequestSignInDetailsHeading';
import RequestSignInDetailsEmail from './RequestSignInDetailsEmail';
import RequestSignInDetailsButtons from './RequestSignInDetailsButtons';
import {
  getShouldRenderErrorScreen,
  getShouldRenderRequestSigninForm,
  getShouldRenderSuccessScreen,
  getIsRequestSigninDisabled,
} from '../../../../store/selectors/RequestSigninPageState';
import TenantPickerLayout from '../../Layout/TenantPickerLayout';
import Logo from '../../Logo/Logo';
import useStyles from './RequestSignInDetailsPage.styles';
import {
  getForgotPasswordIsInternational,
  getForgotPasswordOrgName,
} from '../../../../store/selectors/ForgotPasswordOrg';
import SelectedOrganization from '../../SelectedOrganization/SelectedOrganization';

const RequestSignInDetailsPage = () => {
  const { classes } = useStyles();
  const renderErrorState = useSelector(getShouldRenderErrorScreen);
  const renderSuccessState = useSelector(getShouldRenderSuccessScreen);
  const renderRequestSignInDetailsForm = useSelector(
    getShouldRenderRequestSigninForm,
  );
  const requestSigninDisabled = useSelector(getIsRequestSigninDisabled);
  const organizationName = useSelector(getForgotPasswordOrgName);
  const isInternational = useSelector(getForgotPasswordIsInternational);
  useBootstrapOrgInfo();

  return (
    <TenantPickerLayout>
      <header>
        <Logo />
        <RequestSignInDetailsHeading />
      </header>
      <main>
        <form className={classes.form}>
          {renderRequestSignInDetailsForm && (
            <>
              {!requestSigninDisabled && (
                <>
                  <Typography
                    component="p"
                    variant="body2"
                    className={classes.helpText}
                  >
                    <FormattedMessage id="tenantPicker.requestSiginDetails.helpText" />
                  </Typography>
                  <br />
                  <Typography
                    component="p"
                    variant="body2"
                    className={classes.studentHelpText}
                  >
                    <FormattedMessage id="tenantPicker.requestSiginDetails.studentHelpText" />
                  </Typography>
                </>
              )}
              <SelectedOrganization
                organizationName={organizationName}
                isInternational={isInternational}
              />
              {!requestSigninDisabled && <RequestSignInDetailsEmail />}
            </>
          )}
          {renderErrorState && (
            <SelectedOrganization
              organizationName={organizationName}
              isInternational={isInternational}
            />
          )}
          {renderSuccessState && (
            <Typography variant="body2">
              <FormattedMessage id="tenantPicker.requestSiginDetails.successMessage" />
            </Typography>
          )}
          <RequestSignInDetailsButtons
            canSubmit={
              !requestSigninDisabled && !renderErrorState && !renderSuccessState
            }
          />
        </form>
      </main>
    </TenantPickerLayout>
  );
};

export default RequestSignInDetailsPage;
