const localNweaConfig = {
  name: 'local',
  rootUrl: `https://local.hmhone.hmhco.com:9002`,
  loginUrl: `https://local.hmhone.hmhco.com:9004`,
  loggedInUrl: `https://local.hmhone.hmhco.com:9002/ui/logged-in/`,
  loggedOutUrl: `https://local.hmhone.hmhco.com:9004/logged-out/`,
  helpUrl: 'https://connection.nwea.org/s/support?language=en_US',
  privacyPolicyUrl: 'https://www.nwea.org/policy/privacy-and-terms/',
  termsAndConditionsUrl:
    'https://www.nwea.org/policy/nwea-platform-terms-of-use/',
  forgotPasswordUrlPath: '/ui/login/forgot-password/',
  requestSigninDetailsUrlPath: '/ui/login/request-signin-details/',
  restIdsGridApi: 'https://api.int.br.internal/ids-grid-api',
  edsAuthorizationCode:
    'https://app01.int.tsg.hmhco.com/edcore/eds-login/login/authorization-code/callback',
  susAuth: 'http://int.hmhone.app.hmhco.com/api/authorization',
  authorizationApi: 'http://int.hmhone.app.hmhco.com/api/authorization',
  edsInitUrl:
    'https://app01.int.tsg.hmhco.com/edcore/eds-login/login/initializeUri',
};

module.exports = localNweaConfig;
