import { useLocation } from 'react-router-dom';
import { getIdentifiedPlatformConfig } from '@hmhco/login-config';

const useCallbackParams = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const {
    client_id: clientId,
    iss: issuer,
    state,
    login_hint: loginHint,
  } = Object.fromEntries(params.entries());

  const allKeysHaveValue =
    clientId !== undefined && issuer !== undefined && state !== undefined;

  return {
    allKeysHaveValue,
    params: {
      clientId,
      issuer,
      redirectUri: getIdentifiedPlatformConfig().ampLogin,
      state,
      loginHint,
    },
  };
};

export default useCallbackParams;
