import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PLATFORMS, usePlatform } from '@hmhco/platform-helper';
import useGetCachedOrgInfo from './getCachedOrgInfo.hook';
import {
  useGetConnectionFromStorage,
  useGetConnectionFromUrl,
} from './getConnectionParam.hook';
import { setCachedOrgInfo } from '../store/actions/CountryStateOrgActions';
import useSetUrlParamsInStorage from './setUrlParamsInStorage';
import {
  setCachedOktaWidgetConfig,
  setLoginFlow,
  setLoginStep,
} from '../store/actions/TenantPickerActions';
import { setOktaConfig } from '../storageHelpers/sessionStorageAccessors';
import { useInteractionCodeParamHook } from './useInteractionCodeParam.hook';
import { LOGIN_FLOWS } from '../constants/constants';
import { useLoginHint } from './useLoginHint.hook';
import { handleAuthRedirect } from '../util/handleAuthRedirect';
import { handleLoginHint } from '../util/handleLoginHint';
import { handleOrgInfo } from '../util/handleOrgInfo';
import useCallbackParams from './useCallbackParams.hook';
import { authorize } from '../util/authorize';

function useBootstrapTenantPicker() {
  const dispatch = useDispatch();
  const platform = usePlatform();
  const connectionFromUrl = useGetConnectionFromUrl();
  const connectionFromStorage = useGetConnectionFromStorage();
  const pid = connectionFromUrl || connectionFromStorage;
  const isPidFromUrl = pid === connectionFromUrl;
  const { allKeysHaveValue, params } = useCallbackParams();
  const cachedOrgInfo = useGetCachedOrgInfo(pid);
  const { interactionCode, state } = useInteractionCodeParamHook();
  const loginHint = useLoginHint();
  useSetUrlParamsInStorage();
  const search = new URLSearchParams(window.location.search);
  const resourceId = search.get('resource_id') || '';

  useEffect(() => {
    if (search.has('iss') && !search.has('client_id')) {
      return;
    }

    if (interactionCode && state) {
      dispatch(setLoginStep(3));
      handleAuthRedirect(interactionCode, state);
      return;
    }

    if (loginHint && cachedOrgInfo) {
      handleLoginHint(dispatch, pid, cachedOrgInfo);
      return;
    }

    if (params && allKeysHaveValue) {
      setOktaConfig(JSON.stringify(params));
      dispatch(setLoginStep(2));
      dispatch(setLoginFlow(LOGIN_FLOWS.OKTA));
      dispatch(setCachedOktaWidgetConfig(params));
      /**
       * AUTH-678: NWEA does not need to set the cached org info
       */
      if (platform !== PLATFORMS.NWEA) {
        dispatch(setCachedOrgInfo(cachedOrgInfo));
      }
      return;
    }

    if (pid) {
      handleOrgInfo(dispatch, pid, isPidFromUrl, resourceId);
      return;
    }

    if (cachedOrgInfo) {
      dispatch(setCachedOrgInfo(cachedOrgInfo));
      dispatch(setLoginFlow(2));
      authorize({
        pid,
        loginHint: cachedOrgInfo.data.org.orgRefId,
        ...(resourceId ? { resourceId } : {}),
        isPidFromUrl: false,
      });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);
}

export default useBootstrapTenantPicker;
