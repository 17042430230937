/* eslint-disable import/prefer-default-export */
import { Authorize, getPkceCodeVerifier } from '@hmh/eds-login-js';
import {
  getIdentifiedPlatformClientId,
  getIdentifiedPlatformConfig,
  ISSUER_URL,
} from '@hmhco/login-config';
import { getSignedInUrl } from './getSignedInUrl';

export async function authorize({
  pid,
  loginHint,
  isPidFromUrl,
  resourceId,
  /**
   * Options
   */
  onError,
} = {}) {
  if (typeof pid === 'undefined') {
    throw Error(`pid was not defined: "${pid}"`);
  }
  if (typeof loginHint === 'undefined') {
    throw Error(`loginHint was not defined: "${loginHint}"`);
  }
  if (typeof isPidFromUrl === 'undefined') {
    throw Error(`isPidFromUrl was not defined: "${isPidFromUrl}"`);
  }

  const { edsInitUrl } = getIdentifiedPlatformConfig();

  const isSecure = !!window.location.protocol.match('https:?');
  const pkceCodeChallengeMethod = isSecure ? 'S256' : 'plain';

  await getPkceCodeVerifier(pkceCodeChallengeMethod).then(
    ({ challenge, verifier }) => {
      sessionStorage.setItem('verifier', verifier);
      sessionStorage.setItem('challenge', challenge);
    },
  );

  try {
    const authorization = new Authorize(
      getIdentifiedPlatformClientId(),
      ISSUER_URL,
      edsInitUrl,
      {
        targetLinkUri: getSignedInUrl(),
      },
    );

    const thirdPartyLoginUri = await authorization.getThirdPartyLoginUri({
      connection: pid,
      loginHint,
      ...(resourceId ? { resourceId } : {}),
      /**
       * SSO setting check false if pid is from URL
       * SSO setting check true if pid is from storage
       */
      ssoSettingCheck: !isPidFromUrl,
    });

    window.location = thirdPartyLoginUri;
  } catch (error) {
    onError?.(error);
  }
}
