const intNweaConfig = {
  name: 'int',
  rootUrl: 'https://platform.int.heinemann.com/ui/',
  loginUrl: 'https://platform.int.heinemann.com/ui/login/',
  loggedInUrl: 'https://platform.int.heinemann.com/ui/logged-in/',
  loggedOutUrl: 'https://platform.int.heinemann.com/ui/login/logged-out/',
  helpUrl: 'https://connection.nwea.org/s/support?language=en_US',
  privacyPolicyUrl: 'https://www.nwea.org/policy/privacy-and-terms/',
  termsAndConditionsUrl:
    'https://www.nwea.org/policy/nwea-platform-terms-of-use/',
  forgotPasswordUrlPath: '/ui/login/forgot-password/',
  requestSigninDetailsUrlPath: '/ui/login/request-signin-details/',
  restIdsGridApi: 'https://api.int.br.internal/ids-grid-api',
  edsAuthorizationCode:
    'https://app01.int.tsg.hmhco.com/edcore/eds-login/login/authorization-code/callback',
  susAuth: 'http://int.hmhone.app.hmhco.com/api/authorization',
  authorizationApi: 'http://int.hmhone.app.hmhco.com/api/authorization',
  edsInitUrl:
    'https://app01.int.tsg.hmhco.com/edcore/eds-login/login/initializeUri',
};

module.exports = intNweaConfig;
