/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { LoadingButton } from '@hmhco/loading-button';
import Typography from '@mui/material/Typography';
import { usePlatform } from '@hmhco/platform-helper';
import Link from '@mui/material/Link';
import { PLATFORMS, getConfigForCurrentEnv } from '@hmhco/amp-core-env';
import Button from '@mui/material/Button';
import useStyles from './ActionButtons.styles';
import {
  getCountryStateOrg,
  getCurrentOrg,
  getCurrentPid,
  getRenderNextButton,
} from '../../../store/selectors/CountryStateOrg';
import {
  setCachedOrgInfo,
  setNextButtonPressedFlag,
} from '../../../storageHelpers/sessionStorageAccessors';
import { setConnectionPid } from '../../../storageHelpers/localStorageAccessors';
import {
  getPassword,
  getShowUsernameAndPassword,
  getUsername,
} from '../../../store/selectors/UsernameAndPassword';
import { LOGIN_FLOWS } from '../../../constants/constants';
import {
  getLoginFlow,
  getLoginStep,
} from '../../../store/selectors/TenantPickerState';
import { authorize } from '../../../util/authorize';
import { loginHnm } from '../../../api/loginHnm';
import { login } from '../../../api/login';

/**
 * @summary Checks feature flagging for okta embedded widget, or SSO redirection. Otherwise signs in via eds login.
 * @returns Button
 */
const ActionButtons = () => {
  const { cx, classes } = useStyles();
  const pid = useSelector(getCurrentPid);
  const organization = useSelector(getCurrentOrg);
  const renderSignInButton = useSelector(getShowUsernameAndPassword);
  const renderNextButton = useSelector(getRenderNextButton);
  const orgData = useSelector(getCountryStateOrg);
  const username = useSelector(getUsername);
  const password = useSelector(getPassword);
  const loginFlow = useSelector(getLoginFlow);
  const loginStep = useSelector(getLoginStep);
  const [isGenerating, setIsGenerating] = useState(false);
  const platform = usePlatform();

  const { forgotPassword, requestSigninDetails } = getConfigForCurrentEnv(
    PLATFORMS.ED,
  );

  const handleIntegratedLoginFlow = () => {
    setNextButtonPressedFlag();
    setConnectionPid(pid);
    setCachedOrgInfo(pid, orgData);
    if (platform === PLATFORMS.FLIGHT) {
      return loginHnm({ pid, username, password });
    }
    return login({ pid, username, password });
  };

  const handleContinueToSignIn = async () => {
    setConnectionPid(pid);
    setCachedOrgInfo(pid, orgData);

    await authorize({
      pid,
      loginHint: organization.orgRefId,
      isPidFromUrl: false,
      onError: () => setIsGenerating(false),
    });
  };

  const clickHandler = async event => {
    setIsGenerating(true);
    event.preventDefault();
    if (loginStep === 1) {
      await handleContinueToSignIn();
    }
    if (loginStep === 2) {
      if (loginFlow === LOGIN_FLOWS.INTEGRATED) {
        handleIntegratedLoginFlow();
      }
    }
  };

  const getButtonText = () => {
    if (loginStep === 1) {
      return 'tenantPicker.continueToSignIn';
    }
    return 'tenantPicker.signIn';
  };

  if ((loginFlow === LOGIN_FLOWS.OKTA && loginStep === 2) || loginStep === 3) {
    return null;
  }

  return (
    <div className={classes.root}>
      {loginStep === 2 && (
        <div className={classes.actionLinkContainer}>
          <Typography variant="body1">
            <Link href={forgotPassword} target="_self">
              <FormattedMessage id="login.form.forgotPassword.link" />
            </Link>
          </Typography>
        </div>
      )}
      <div
        className={cx(classes.actionLinkContainer, {
          [classes.actionLinkContainerMultiple]: loginStep === 2,
        })}
      >
        {loginStep === 2 && (
          <Button
            size="small"
            target="_self"
            className={cx(
              classes.signInDetailsLink,
              classes.signInDetailsButton,
            )}
            component={Link}
            href={requestSigninDetails}
            role="link"
            variant="outlined"
          >
            <FormattedMessage id="tenantPicker.requestSiginDetails" />
          </Button>
        )}
        <FormattedMessage id={getButtonText()}>
          {([label]) => (
            <LoadingButton
              loading={isGenerating}
              disabled={
                (!renderSignInButton && !renderNextButton) || !organization
              }
              buttonProps={{
                children: label,
                color: 'primary',
                className: classes.button,
                variant: 'contained',
                size: 'small',
                onClick: clickHandler,
                type: 'submit',
              }}
              loadingText={label}
            />
          )}
        </FormattedMessage>
      </div>
    </div>
  );
};

export default ActionButtons;
