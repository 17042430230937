import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'OktaFormSkeleton' })(() => ({
  container: {
    marginTop: '5px',
  },
  label: {
    marginBottom: '2px',
  },
  field: {
    marginBottom: '25px',
  },
  button: {
    marginBottom: '15px',
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
