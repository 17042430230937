import React from 'react';
import errorImg from '@ed/baseline/images/library-illustrations/404-sm.png';
import { useLocation } from 'react-router-dom';
import useStyles from './Error.styles';

const Error = () => {
  const { classes } = useStyles();

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const {
    error_code: errorCode,
    error,
    error_description: errorDescription,
    date,
    state,
    trace_id: traceId,
  } = Object.fromEntries(params.entries());

  return (
    <div className={classes.errorContainer}>
      <div>
        <h1 className={classes.heading}>%Error #%</h1>
        <h2 className={classes.heading2}>Something went wrong!</h2>
        <div className={classes.content}>
          {errorCode && <p>Error Code: {errorCode}</p>}
          {error && <p>Error: {error}</p>}
          {errorDescription && <p>Error Description: {errorDescription}</p>}
          {date && <p>Date: {date}</p>}
          {state && <p>State: {state}</p>}
          {traceId && <p>Trace ID: {traceId}</p>}
        </div>
      </div>
      <div className={classes.imgContainer}>
        <img className={classes.errorImg} src={errorImg} alt="" />
      </div>
    </div>
  );
};

export default Error;
