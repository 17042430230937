import { createAxiosCancelable } from '@hmhco/core-network/src/axiosHelpers';
import requestHelper from '@hmhco/core-network/src/utils/requestHelper';
import { getIdentifiedPlatformConfig } from '@hmhco/login-config';

const domainConfigUrl = '/api/v1/domains';
const domainVerifyUrl = '/api/v1/domains/verify';

export const fetchDomainList = (pactConfig = {}) => {
  const { client, cancel, cancelToken, isCancel } = createAxiosCancelable({
    includeAuth: false,
  });
  const request = requestHelper({
    client,
    cancelToken,
    isCancel,
    baseURL: getIdentifiedPlatformConfig().fetchDomainList,
    pactConfig,
  });

  return {
    fetch: () => request.get(domainConfigUrl),
    cancel,
  };
};

export const verifyDomain = (pactConfig = {}) => {
  const { client, cancel, cancelToken, isCancel } = createAxiosCancelable({
    includeAuth: false,
  });
  const request = requestHelper({
    client,
    cancelToken,
    isCancel,
    baseURL: getIdentifiedPlatformConfig().verifyDomain,
    pactConfig,
  });

  return {
    verify: ({ email, pid }) =>
      request.get(domainVerifyUrl, {
        params: { email, pid },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }),
    cancel,
  };
};
