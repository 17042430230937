import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'CaptchaComponent' })(() => ({
  captchaSubtitle: {
    marginBottom: 'var(--ebl-s3)',
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  submitButton: {
    margin: '0',
  },
}));
