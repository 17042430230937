import logErrorWithContext from '@hmhco/client-monitoring/src/context/logErrorWithContext';
import {
  deleteCookie,
  getCookie,
} from '@hmhco/cookie-accessors/src/cookieHelper';
import { setCachedOrgInfo } from '../store/actions/CountryStateOrgActions';
import getOrgInfoClient from '../api/getOrgInfo';
import { getStateFromStateCode } from '../constants/usStates';
import { setDidPidFail } from '../store/actions/TenantPickerActions';
import { setConnectionPid } from '../storageHelpers/localStorageAccessors';
import { setCachedOrgInfo as cacheOrgInfo } from '../storageHelpers/sessionStorageAccessors';
import { authorize } from './authorize';
import { FAILED_LOGIN_COOKIE } from '../hooks/checkLoginCookies.hook';

export const handleOrgInfo = async (
  dispatch,
  pid,
  isPidFromUrl,
  resourceId,
) => {
  try {
    /**
     * If it has cookie and it doesnt have pid then it was returned from pid failed login
     * so we consider PID as true
     *
     * If it has cookie and pid it means it was not going from failed redirect
     * so we delete cookie and set hasError to false
     */
    let hasError;
    if (getCookie(FAILED_LOGIN_COOKIE)) {
      if (isPidFromUrl) {
        deleteCookie();
        hasError = false;
      } else {
        hasError = true;
      }
    }

    const { getOrgInfo } = getOrgInfoClient();

    const organization = await getOrgInfo(pid);

    const orgState = getStateFromStateCode(organization.stateCode);
    const country = organization.countryCode
      ? { code: organization.countryCode, name: organization.countryName }
      : { code: 'US', name: 'United States' };
    const newState = {
      data: {
        country,
        state: orgState,
        org: { pid, name: organization.name, orgRefId: organization.orgId },
      },
    };
    dispatch(setCachedOrgInfo(newState));

    cacheOrgInfo(pid, newState.data);
    setConnectionPid(pid);

    await authorize({
      pid,
      loginHint: organization.orgId,
      isPidFromUrl: hasError || isPidFromUrl,
      ...(resourceId ? { resourceId } : {}),
    });
  } catch (error) {
    dispatch(setDidPidFail(true));
    logErrorWithContext('Login app - Error in Organization info', [
      { key: 'errorMessage', value: error },
    ]);
  }
};

export default handleOrgInfo;
