import { createSelector } from 'reselect';

export const getTenantPickerState = state => state.tenantPickerState;

export const getOktaReady = createSelector(
  [getTenantPickerState],
  tenantPickerState => Boolean(tenantPickerState.oktaReady),
);

export const getCachedOktaWidgetConfig = createSelector(
  [getTenantPickerState],
  tenantPickerState => tenantPickerState.oktaWidgetConfig,
);

export const getDidPidFail = createSelector(
  [getTenantPickerState],
  tenantPickerState => tenantPickerState.didPidFail,
);

export const getUserAlert = createSelector(
  [getTenantPickerState],
  tenantPickerState => tenantPickerState.userAlert,
);

export const getLoginStep = createSelector(
  [getTenantPickerState],
  tenantPickerState => tenantPickerState.loginStep,
);

export const getLoginFlow = createSelector(
  [getTenantPickerState],
  tenantPickerState => tenantPickerState.loginFlow,
);

export const getLoginHint = createSelector(
  [getTenantPickerState],
  tenantPickerState => tenantPickerState.loginHint,
);
