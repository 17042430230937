import React from 'react';
import { useIntl } from 'react-intl';
import { usePlatform } from '@hmhco/platform-helper';
import { Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { getLoginStep } from '../../../store/selectors/TenantPickerState';

const AuthRedirect = () => {
  const { formatMessage } = useIntl();
  const platform = usePlatform();
  const message = formatMessage(
    { id: 'tenantPicker.authRedirect' },
    { platform },
  );
  const loginStep = useSelector(getLoginStep);

  if (loginStep < 3) return null;

  return (
    <Stack alignItems="center" spacing="var(--ebl-s2)" sx={{ width: '100%' }}>
      <Typography component="h1" variant="h3">
        {message}
      </Typography>
    </Stack>
  );
};

export default AuthRedirect;
