/* eslint-disable no-use-before-define */
import { eventRegistry as events } from '@hmhco/amp-core-events';
import { PLATFORMS, getConfigForCurrentEnv } from '@hmhco/amp-core-env';
import logErrorWithContext from '@hmhco/client-monitoring/src/context/logErrorWithContext';
import { isVanillaRoute } from '@hmhco/amp-vanilla-routes';
import sifDecoder, {
  rawSifParser,
  getTokenInfoFromSifToken,
  decodeSifBaseAsObject,
  decodeFromBase64IdToken,
} from './sifDecoder';
import { getLoginMethod, LOGIN_METHODS } from './getLoginMethod';

const config = getConfigForCurrentEnv(PLATFORMS.ED);

export const ED_PLATFORM_CODE = 'IDS';
export const BASAL_PLATFORM_CODES = ['tc', 'tck', 'hrw', 'hmof'];
export const PLATFORM_REDIRECTS = {
  [ED_PLATFORM_CODE]: config.loggedOutUrl,
  tc: config.tcLoginPath,
  tck: config.tcLoginPath,
  hrw: config.hmoLoginPath,
  hmof: config.hmoLoginPath,
};

let userContext = null;

export const ADMIN_ROLES = {
  SCHOOL: 'School Administrator',
  DISTRICT: 'District Administrator',
  ADMINISTRATOR: 'Administrator',
  PLATFORM: 'Platform Administrator',
};

export const TEACHER_ROLE = 'Instructor';

export const LEARNER_ROLE = 'Learner';

export const PREVIEW_CODE = 'PREVIEW';

export const SUPERDISTRICT_PID = '88008866';

/**
 * The Ed login app sets this key on successful login.
 */
/** !!! IMPORT FOR TESTING ONLY !!! */
export const ED_LOGIN_AUTH_KEY = `com.hmhco.security.openID.authInfo.${config.name}`;

/**
 * Clear the user token memory cache
 */
export const clearUserCtx = () => {
  userContext = null;
};

/**
 * Delete SIF token from storage then clear user context via the USER_CTX_UPDATE custom event.
 * Represents LOGOUT action
 */
export const logout = () => {
  let logoutUrl = config.loggedOutUrl;

  if (getLoginMethod() !== LOGIN_METHODS.SSO) {
    /**
     * For feature flag OFF (Legacy HIP SSO logout) and platform logout
     * */
    logoutUrl = getLegacyHipAuthorizeLogoutUrl(config.loggedOutUrl);
  }

  clearUserCtx();
  window.sessionStorage.removeItem(ED_LOGIN_AUTH_KEY);
  window.sessionStorage.removeItem('HCP_AUTH');
  window.sessionStorage.removeItem('allowed_capabilities');
  window.sessionStorage.removeItem('onboardingStatusOnLoad');
  const userCtxEvent = new CustomEvent(events.USER_CTX_UPDATE, {
    detail: null,
  });
  window.dispatchEvent(userCtxEvent);

  window.location.assign(logoutUrl);
};

/**
 * Reads the requested element from a decoded string SIF token.
 * @param {string} item name of item to be retrieved
 * @param {string} decodedSifToken the SIF token object in string format
 * @returns {string}
 */
export const getFromToken = (item, decodedSifToken) => {
  const tokenSubItems = {
    userName: 'cn',
    userId: 'uniqueIdentifier',
    districtPid: 'dc',
    schoolPid: 'o',
    state: 'st',
  };
  const parsedSifToken = decodedSifToken && JSON.parse(decodedSifToken);

  return item &&
    decodedSifToken &&
    parsedSifToken.sub.includes(`${tokenSubItems[item]}=`)
    ? parsedSifToken.sub.split(`${tokenSubItems[item]}=`)[1].split(',')[0]
    : null;
};

/**
 * Extract role information from SIF token
 *
 * @param {string} decodedSifToken SIF token json encoded object
 * @returns {array} list of user roles: 'Learner', 'Instructor', 'Administrator'
 */
export const getRolesFromToken = decodedSifToken => {
  return (
    decodedSifToken &&
    JSON.parse(decodedSifToken)[
      'http://www.imsglobal.org/imspurl/lis/v1/vocab/person'
    ]
  );
};

/**
 * Reads the user's role from a decoded string SIF token.
 * Represents role
 *
 * @param {string} decodedSifToken SIF token json encoded object
 *
 * @returns {string} role for current user (teacher, student, administrator)
 */
export const getRoleFromToken = decodedSifToken => {
  return decodedSifToken && getRolesFromToken(decodedSifToken)[0];
};

/**
 * Extract first name in a full name string
 *
 * @param {string} userName string containing first name and last name of user
 *
 * @returns {string} user first name
 */
export const getFirstName = userName =>
  typeof userName === 'string' ? userName.split(' ')[0] : '';

/**
 * Extract last name in a full name string
 *
 * @param {string} userName string containing first name and last name of user
 *
 * @returns {string} user last name
 */
export const getLastName = userName => {
  if (typeof userName !== 'string') {
    return '';
  }
  const nameParts = userName.split(' ');
  if (nameParts.length <= 1) {
    return '';
  }
  return nameParts.pop();
};

/**
 * determine if the token is from Basal platform
 *
 * @param {string} decodedSifToken SIF token json encoded object
 *
 * @returns {boolean} true if the user is from legacy platform, false otherwise
 */
export const isLegacyPlatformToken = decodedSifToken => {
  const parsedToken = (decodedSifToken && JSON.parse(decodedSifToken)) || {};
  const platform = parsedToken.platform || parsedToken.PlatformId;

  return Boolean(
    platform && BASAL_PLATFORM_CODES.includes(platform.toLowerCase()),
  );
};

/**
 * Determine the school category the user belong to
 *
 * @param {string} decodedSifToken SIF token json encoded object
 *
 * @returns {string} school category for the user: 'public' or 'private'
 */
export const getSchoolCategory = decodedSifToken => {
  return decodedSifToken && JSON.parse(decodedSifToken).school_category;
};

/**
 * Determine if the user belong to a private schoool
 *
 * @param {string} decodedSifToken SIF token json encoded object
 *
 * @returns {boolean} true if the user is from a private school, false otherwise
 */
export const isPrivateSchool = decodedSifToken => {
  return getSchoolCategory(decodedSifToken) === 'private';
};

/**
 * Determine if the user is an administrator belonging to a private schoool
 *
 * @param {object} userCtx user context with SIF token json encoded object
 *
 * @returns {boolean} true if the user  is an administrator and belong to a private school, false otherwise
 */
export const isPrivateSchoolAdmin = userCtx =>
  userCtx?.isSchoolAdmin && isPrivateSchool(userCtx.sif);

/**
 * Determine if the user is an administrator belonging to a public schoool
 *
 * @param {object} userCtx user context with SIF token json encoded object
 *
 * @returns {boolean} true if the user  is an administrator and belong to a public school, false otherwise
 */
export const isPublicSchoolAdmin = userCtx =>
  userCtx?.isSchoolAdmin && !isPrivateSchool(userCtx.sif);

/**
 * Extract the school ref ID the user belong to
 *
 * @param {string} decodedSifToken SIF token json encoded object
 *
 * @returns {string} school ref id the user belong to
 */
export const getSchoolId = decodedSifToken => {
  return decodedSifToken && JSON.parse(decodedSifToken).school_id;
};

/**
 * Extract the district ref ID the user belong to
 *
 * @param {string} decodedSifToken SIF token json encoded object
 *
 * @returns {string} district ref id the user belong to
 */
export const getDistrictId = decodedSifToken => {
  return decodedSifToken && JSON.parse(decodedSifToken).dist_id;
};

/**
 * Determine the type of administrator the user is
 *
 * @param {string} decodedSifToken SIF token json encoded object
 *
 * @returns {string} district ref id the user belong to
 */
export const getAdminRole = decodedSifToken => {
  if (!decodedSifToken) {
    return null;
  }

  const districtPid = getFromToken('districtPid', decodedSifToken);
  const schoolPid = getFromToken('schoolPid', decodedSifToken);

  if (districtPid === SUPERDISTRICT_PID) {
    return ADMIN_ROLES.PLATFORM;
  }
  if (districtPid === schoolPid) {
    return isPrivateSchool(decodedSifToken)
      ? ADMIN_ROLES.SCHOOL
      : ADMIN_ROLES.DISTRICT;
  }
  return ADMIN_ROLES.SCHOOL;
};

/**
 * Extract all roles the user is assigned to
 *
 * @param {string} decodedSif SIF token json encoded object
 *
 * @returns {array} list of roles
 */
export const getAllRoles = decodedSif => {
  const roles = getRolesFromToken(decodedSif) || [];
  if (roles.includes(ADMIN_ROLES.ADMINISTRATOR)) {
    roles.push(getAdminRole(decodedSif));
  }
  return roles;
};

/**
 * Determine if the user is a District administrator
 *
 * @param {array} roles the user has
 *
 * @returns {boolean} true of the user is a district administrator, false otherwise
 */
export const isDistrictAdmin = roles => {
  return roles.includes(ADMIN_ROLES.DISTRICT);
};

/**
 * Determine if the user is a Student
 *
 * @param {array} roles the user has
 *
 * @returns {boolean} true of the user is a student, false otherwise
 */
export const isLearner = roles => {
  return roles.includes(LEARNER_ROLE);
};

/**
 * Determine if the user is a School administrator
 *
 * @param {array} roles the user has
 *
 * @returns {boolean} true of the user is a School administrator, false otherwise
 */
export const isSchoolAdmin = roles => {
  return roles.includes(ADMIN_ROLES.SCHOOL);
};

/**
 * Determine if the user is a Preview User
 *
 * @param {array} roles the user has
 *
 * @returns {boolean} true of the user is a Preview User, false otherwise
 */
export const isPreviewUser = tokeninfo => {
  return tokeninfo?.account_type === PREVIEW_CODE;
};

/**
 * read session storage to return the SIF token
 *
 * @returns {string} SIF token stored in session storage
 */
const getRawToken = () => {
  // we already have it from login
  return window.sessionStorage.getItem(ED_LOGIN_AUTH_KEY); // return null if the key doesn't exists
};

/**
 * read local storage to return the SIF token
 *
 * @returns {string} SIF token stored in local storage
 */
const getRawTokenFromLocalStorage = () => {
  return window.localStorage.getItem(ED_LOGIN_AUTH_KEY); // return null if the key doesn't exists
};

/**
 * Determine if the user is an evaluator
 *
 * @param {string} decodedSif SIF token json encoded object
 * @returns {boolean} true if user is an evaluator, false otherwise
 */
export const isEvaluator = decodedSif => {
  return (
    getFromToken('state', decodedSif) === 'EV' &&
    getRolesFromToken(decodedSif).includes(TEACHER_ROLE)
  );
};

/**
 * On dev it sets the SIF token to storage from the environment variables. On the environments it already has that from Login
 * Represents userContext
 * @returns {object} format: { rawToken: string, sif: string, userId: string, userName: string, firstName: string, role: string }
 */
const tokenProcess = rawToken => {
  const sif = sifDecoder(rawToken);
  const tokeninfo = getTokenInfoFromSifToken(rawToken);
  const userName = getFromToken('userName', sif);
  const role = getRoleFromToken(sif);
  const roles = getAllRoles(sif);

  const userCtx = {
    rawToken: rawSifParser(rawToken),
    sif,
    userId: getFromToken('userId', sif),
    userName,
    firstName: getFirstName(userName),
    lastName: getLastName(userName),
    role,
    roles,
    districtPid: getFromToken('districtPid', sif),
    schoolPid: getFromToken('schoolPid', sif),
    districtId: getDistrictId(sif),
    schoolId: getSchoolId(sif),
    isDistrictAdmin: isDistrictAdmin(roles),
    isSchoolAdmin: isSchoolAdmin(roles),
    isLearner: isLearner(roles),
    isPreviewUser: isPreviewUser(tokeninfo),
    isEvaluator: isEvaluator(sif),
    logout,
  };
  return userCtx;
};

/**
 * Read the user token and format its information
 *
 * @returns {object} structure provinding information on the user: role, school ref id, district ref id, etc.
 *
 * @deprecated please use `getCommonUserContext` out of `@hmhco/common-user-context`
 */
export const getUserCtx = () => {
  const envConfig = getConfigForCurrentEnv(PLATFORMS.ED);
  // Deleting your SIF should only happen when you are running AMP locally
  // and are not running the E2Es
  if (userContext) {
    return userContext;
  }

  if (envConfig.name === 'devCert') {
    const SIF_OBJECT = JSON.stringify({
      sif: {
        accessToken: process.env.SIF_TOKEN,
        tokeninfo: decodeSifBaseAsObject(process.env.SIF_TOKEN),
        idToken: decodeFromBase64IdToken(process.env.SIF_TOKEN),
      },
    });
    window.sessionStorage.setItem(ED_LOGIN_AUTH_KEY, SIF_OBJECT);
  }

  try {
    if (!isVanillaRoute()) {
      userContext = tokenProcess(getRawToken());
    } else {
      const rawToken = getRawToken() || getRawTokenFromLocalStorage();
      userContext = tokenProcess(rawToken);
    }

    if (sessionStorage.getItem('accountConfiguration')) {
      userContext.defaultAccountType =
        JSON.parse(sessionStorage.getItem('accountConfiguration'))
          ?.defaultAccountType || 'individual';
    } else {
      sessionStorage.setItem(
        'accountConfiguration',
        JSON.stringify({ defaultAccountType: 'individual' }),
      );
      userContext.defaultAccountType = 'individual';
    }
  } catch (error) {
    logErrorWithContext('FailedGetUserContext', [
      { key: 'errorMessage', value: error },
    ]);
  }
  return userContext;
};

/**
 * If SIF and ONLY SIF changed, trigger a custom event
 * Represents custom user context update/change event
 *
 * @param {StorageEvent} event
 */
export const sifChanged = event => {
  if (event.key === ED_LOGIN_AUTH_KEY) {
    clearUserCtx();
    const sifEvent = sifDecoder(event.newValue);
    const userName = getFromToken('userName', sifEvent);
    const roles = getAllRoles(sifEvent);
    const userCtxEvent = new CustomEvent(events.USER_CTX_UPDATE, {
      detail: {
        rawToken: rawSifParser(event.newValue),
        sif: sifEvent,
        userId: getFromToken('userId', sifEvent),
        userName,
        firstName: getFirstName(userName),
        lastName: getLastName(userName),
        role: getRoleFromToken(sifEvent),
        roles,
        isDistrictAdmin: isDistrictAdmin(roles),
        isSchoolAdmin: isSchoolAdmin(roles),
        isLearner: isLearner(roles),
        districtPid: getFromToken('districtPid', sifEvent),
        isEvaluator: isEvaluator(sifEvent),
      },
    });

    window.dispatchEvent(userCtxEvent);
  }
};

export function getPlatform() {
  const ctx = getUserCtx();
  return ctx?.rawToken?.sif?.tokeninfo?.PlatformId || '';
}

export function getIdToken(ctx) {
  return ctx?.rawToken?.sif?.idToken;
}

export function clientRedirect(newUrl) {
  window.location.assign(newUrl);
}

export function getLegacyHipAuthorizeLogoutUrl(redirectUrl) {
  const ctx = getUserCtx();
  const idToken = getIdToken(ctx);
  const authorizeUrl = '/api/authorization/logout';
  const clientId = '152ced50-1369-4b19-8b26-8f3d5d9bfd6a.hmhco.com';
  const urlSearchParams = new URLSearchParams({
    id_token_hint: idToken,
    client_id: clientId,
    post_logout_redirect_uri: redirectUrl,
    state: '{}',
  });
  return `${authorizeUrl}?${urlSearchParams.toString()}`;
}
