/* eslint-disable import/prefer-default-export */
import { getCurrentEnv } from '@hmhco/amp-core-env';
import {
  ENVIRONMENTS,
  PLATFORMS,
} from '../../../../../environments/globalConstants';
import { identifyPlatform } from './identifyPlatform';
import {
  LOCAL_LEGACY_FLIGHT_CLIENT_ID,
  LEGACY_FLIGHT_CLIENT_ID,
} from '../constants';

/**
 * ! SHOULD BE USED ONLY BY HCP LOGIN UI
 * ! CONSULT WITH ENABLED TEAM BEFORE USING IT ANYWHERE ELSE
 */
export function getLegacyFlightClientId() {
  if (identifyPlatform() === PLATFORMS.FLIGHT) {
    if (getCurrentEnv() === ENVIRONMENTS.local) {
      return LOCAL_LEGACY_FLIGHT_CLIENT_ID;
    }
    return LEGACY_FLIGHT_CLIENT_ID;
  }
  return null;
}
