import React from 'react';
import { TextField } from '@mui/material';
import { func, string } from 'prop-types';
import useStyles from './Input.styles';
import { AUTO_COMPLETE } from '../../../../constants/constants';

const Password = props => {
  const {
    handlePasswordChange,
    errorMessage,
    passwordLabel,
    elementId,
    dataTestId,
  } = props;
  const { classes } = useStyles();

  return (
    <TextField
      onChange={handlePasswordChange}
      helperText={errorMessage}
      error={Boolean(errorMessage)}
      inputProps={{ 'data-testid': dataTestId, className: classes.input }}
      FormHelperTextProps={{
        'aria-live': 'polite',
      }}
      label={passwordLabel}
      id={elementId}
      name={elementId}
      type="password"
      required
      autoComplete={AUTO_COMPLETE.NEW_PASSWORD}
    />
  );
};

Password.propTypes = {
  handlePasswordChange: func,
  errorMessage: string,
  passwordLabel: string,
  elementId: string,
  dataTestId: string,
};

export default Password;
