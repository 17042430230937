import { PlanktonProductConfig } from './PlanktonProductConfig';
import LocalCalkinsProductConfig from './CalkinsProduct.config.local';
import DevCalkinsProductConfig from './CalkinsProduct.config.dev';
import IntCalkinsProductConfig from './CalkinsProduct.config.int';
import CertCalkinsProductConfig from './CalkinsProduct.config.cert';
import ProdCalkinsProductConfig from './CalkinsProduct.config.prod';
import LocalDtmProductConfig from './DtmProduct.config.local';
import DevDtmProductConfig from './DtmProduct.config.dev';
import IntDtmProductConfig from './DtmProduct.config.int';
import CertDtmProductConfig from './DtmProduct.config.cert';
import ProdDtmProductConfig from './DtmProduct.config.prod';

export type Environment = 'local' | 'dev' | 'int' | 'cert' | 'prod';

export enum ProductNames {
  LUCY_CALKINS = 'calkins',
  DO_THE_MATH = 'dtm',
  DO_THE_MATH_MS = 'dtmms',
  MATIFIC = 'matific',
  MATH_EXPRESSIONS = 'mx',
}

export enum ProductIdTypes {
  ISBN = 'ISBN',
  ONECMS = 'onecms',
  SKU = 'SKU',
}
const oneCmsEnvConfigObject = {
  local: LocalDtmProductConfig,
  dev: DevDtmProductConfig,
  int: IntDtmProductConfig,
  cert: CertDtmProductConfig,
  prod: ProdDtmProductConfig,
};
const skuEnvConfigObject = {
  local: LocalCalkinsProductConfig,
  dev: DevCalkinsProductConfig,
  int: IntCalkinsProductConfig,
  cert: CertCalkinsProductConfig,
  prod: ProdCalkinsProductConfig,
};
export const productNameToAuthorMap: Record<ProductNames, string> = {
  [ProductNames.LUCY_CALKINS]: 'lucy calkins',
  [ProductNames.DO_THE_MATH]: 'marilyn burns',
  [ProductNames.MATIFIC]: 'math activities',
};

export const productAuthorToProductNameMap = {
  'lucy calkins': 'calkins',
  'marilyn burns': 'dtm',
  'math activities': 'matific',
  'lorna simmons': 'saxon',
  'karen fuson': 'mx',
};

export const ProductIdentifier = {
  calkins: 'UOS',
  dtm: 'DTM',
  dtmMs: 'DTMMS',
  saxon: 'SAXON',
  mx: 'MX',
};

const productConfigsMap: Record<
  ProductNames | ProductIdTypes,
  Record<Environment, PlanktonProductConfig>
> = {
  [ProductNames.LUCY_CALKINS]: skuEnvConfigObject,
  [ProductNames.DO_THE_MATH]: oneCmsEnvConfigObject,
  [ProductNames.DO_THE_MATH_MS]: oneCmsEnvConfigObject,
  [ProductNames.MATIFIC]: oneCmsEnvConfigObject,
  [ProductIdTypes.SKU]: skuEnvConfigObject,
  [ProductIdTypes.ONECMS]: oneCmsEnvConfigObject,
  [ProductIdTypes.ISBN]: oneCmsEnvConfigObject,
};

type ProductConfigInputs = {
  productId?: string;
  environment?: Environment;
  productIdType?: string;
};

// eslint-disable-next-line import/prefer-default-export
export const getProductConfig = <TConfig extends PlanktonProductConfig>({
  productId = '',
  environment = (process.env.RUNTIME_ENV || window.RUNTIME_ENV) as Environment,
  productIdType = 'onecms',
}: ProductConfigInputs): TConfig => {
  const config: TConfig =
    productConfigsMap[productIdType]?.[environment] ||
    (productConfigsMap[productIdType].local as TConfig);
  // SHOULD: Improve code so we are not hard coding lucy-calkins since it does not scale
  const copyConfig: TConfig = {
    planktonVersionPrefix: config.planktonVersionPrefix,
    edsLtiRceToolId: config.edsLtiRceToolId,
    edsLtiVideoToolId: config.edsLtiVideoToolId,
  } as TConfig;
  if (productIdType !== ProductIdTypes.SKU) {
    copyConfig.planktonVersionPrefix = copyConfig.planktonVersionPrefix.replace(
      ':PRODUCT_ID',
      productId,
    );
  }
  return copyConfig;
};
