const PLATFORMS = {
  ED: 'HMH',
  FLIGHT: 'HNM',
  NWEA: 'NWEA',

  // SUB PLATFORMS
  FLIGHT_CONTENT_SHARE: 'HNM-CONTENT-SHARE',
  SUPPORT_TOOL: 'SUPPORT-TOOL',
};

const DEFAULT_CONFIGURATIONS = {
  [PLATFORMS.ED]: {
    name: PLATFORMS.ED,
    platform: PLATFORMS.ED,
    predicate: 'hmh',
    showDesktopNavbar: true,
    showMobileNavbar: true,
    showLMS: true,
    showUpdatesBanner: true,
  },
  [PLATFORMS.FLIGHT]: {
    name: PLATFORMS.FLIGHT,
    predicate: 'heinemann',
    showDesktopNavbar: false,
    showMobileNavbar: false,
    showLMS: false,
    showUpdatesBanner: false,
  },
  [PLATFORMS.NWEA]: {
    name: PLATFORMS.NWEA,
    predicate: 'nwea',
    showDesktopNavbar: false,
    showMobileNavbar: false,
    showLMS: false,
    showUpdatesBanner: false,
  },
};

/**
 * to ensure backwards compatibility, we basically serve the same config objects we did before
 * This pattern leaves us space to integrate other configs later
 */
const CONFIGURATIONS = {
  [PLATFORMS.ED]: DEFAULT_CONFIGURATIONS[PLATFORMS.ED],
  [PLATFORMS.FLIGHT]: DEFAULT_CONFIGURATIONS[PLATFORMS.FLIGHT],
  [PLATFORMS.NWEA]: DEFAULT_CONFIGURATIONS[PLATFORMS.NWEA],

  // SUB PLATFORMS
  [PLATFORMS.FLIGHT_CONTENT_SHARE]: DEFAULT_CONFIGURATIONS[PLATFORMS.FLIGHT],
  [PLATFORMS.SUPPORT_TOOL]: DEFAULT_CONFIGURATIONS[PLATFORMS.ED],
};

const ENVIRONMENTS = {
  local: 'local',
  dev: 'dev',
  int: 'int',
  cert: 'cert',
  devCert: 'devCert',
  prod: 'prod',
};

Object.freeze(PLATFORMS);
Object.freeze(ENVIRONMENTS);

module.exports = {
  PLATFORMS,
  ENVIRONMENTS,
  CONFIGURATIONS,
  DEFAULT_CONFIGURATIONS,
};
