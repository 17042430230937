import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Username from './Username';
import Password from './Password';
import useGetUserHasNavigatedFromDistrictSelect from '../../hooks/getUserHasNavigatedFromDistrictSelect.hook';
import SelectedOrganization from '../TenantPicker/SelectedOrganization/SelectedOrganization';
import { setLoginStep } from '../../store/actions/TenantPickerActions';
import {
  getCurrentOrg,
  getRenderInternationalForm,
} from '../../store/selectors/CountryStateOrg';
import {
  getLoginFlow,
  getLoginStep,
} from '../../store/selectors/TenantPickerState';
import { LOGIN_FLOWS } from '../../constants/constants';

const HCPUsernameAndPasswordForm = () => {
  const shouldUsernameFocus = useGetUserHasNavigatedFromDistrictSelect();
  const dispatch = useDispatch();
  const currentOrg = useSelector(getCurrentOrg);
  const isInternational = useSelector(getRenderInternationalForm);
  const loginStep = useSelector(getLoginStep);
  const loginFlow = useSelector(getLoginFlow);

  const handleChangeClick = () => {
    dispatch(setLoginStep(1));
  };

  if (loginStep === 1 || loginFlow !== LOGIN_FLOWS.INTEGRATED) {
    return null;
  }
  return (
    <>
      <SelectedOrganization
        organizationName={currentOrg?.name}
        isInternational={isInternational}
        onClick={handleChangeClick}
      />
      <Username shouldUsernameFocus={shouldUsernameFocus} />
      <Password />
    </>
  );
};
export default HCPUsernameAndPasswordForm;
