import React from 'react';
import { TextField } from '@mui/material';
import { func, string } from 'prop-types';
import { useIntl } from 'react-intl';
import useStyles from './Input.styles';
import { AUTO_COMPLETE } from '../../../../constants/constants';

const NameField = ({
  name,
  nameLabel,
  handleChange,
  handleBlur,
  errorMessageId,
  elementId,
}) => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();
  const errorMessage = errorMessageId
    ? formatMessage({ id: errorMessageId })
    : '';

  return (
    <TextField
      value={name}
      error={Boolean(errorMessageId)}
      helperText={errorMessage}
      onChange={handleChange}
      onBlur={handleBlur}
      inputProps={{ className: classes.input }}
      label={nameLabel}
      id={elementId}
      name="field-name"
      required
      autoComplete={AUTO_COMPLETE.GIVEN_NAME}
    />
  );
};

NameField.propTypes = {
  name: string.isRequired,
  nameLabel: string.isRequired,
  handleChange: func.isRequired,
  handleBlur: func.isRequired,
  errorMessageId: string,
  elementId: string.isRequired,
};

export default NameField;
