import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { ALERT_SIZE, HcpAlert, SEVERITY } from '@hmhco/hcp-alert';
import { PLATFORMS, usePlatform } from '@hmhco/platform-helper';
import useInputStyles from './Input.styles';
import OrderNumber from './OrderNumber';
import NameField from './NameField';
import Username from './Username';
import Password from './Password';
import CreateAccountButton from './CreateAccountButton';
import VerifyPassword from './VerifyPassword';
import registerNewDistrictAdministratorApi from '../../../../api/registerNewDistrictAdministratorApi';
import {
  HTTP_STATUS_CODES,
  PLATFORM_IDS,
  QUERY_PARAMS,
} from '../../../../constants/constants';
import usernameValidator from '../../../../util/usernameValidator';
import passwordValidator from '../../../../util/passwordValidator';
import nameFieldValidator from '../../../../util/nameFieldValidator';
import TenantPickerLayout from '../../Layout/TenantPickerLayout';
import Logo from '../../Logo/Logo';
import useStyles from './RegisterUserPage.styles';
import RequiredFieldsNote from '../../RequiredFieldsNote/RequiredFieldsNote';
import OrderNumberLink from './OrderNumberLink';

const errorMessageIds = {
  usernameExists: 'registerPage.userName.exists',
  failedToRegisterUser: 'registerPage.message.error.failedToRegisterUser',
  orderNumberRequired: 'registerPage.errorMessage.orderNumberRequired',
  referenceNumberRequired: 'registerPage.errorMessage.referenceNumberRequired',
  usernameMatchesPassword: 'registerPage.password.matchesUsername',
  passwordsDoNotMatch: 'login.newPassword.errorMessage.passwordsDoNotMatch',
  verifyPasswordIsRequired: 'registerPage.verifyPassword.isRequired',
};

const RegisterUserPage = () => {
  const { classes } = useStyles();
  const platform = usePlatform();
  const [userDetails, setUserDetails] = useState({
    orderNumber: '',
    firstName: '',
    lastName: '',
    username: '',
    password: '',
    verifyPassword: '',
  });
  const [touched, setTouched] = useState({});
  const [apiErrorMessageIds, setApiErrorMessageIds] = useState({
    invalidLink: undefined,
    usernameExists: undefined,
  });
  const {
    registerNewDistrictAdministrator,
  } = registerNewDistrictAdministratorApi();
  const queryParams = new URLSearchParams(useLocation().search);
  const token = queryParams.get(QUERY_PARAMS.TOKEN);
  const connection = queryParams.get(QUERY_PARAMS.CONNECTION);
  const history = useHistory();
  const { classes: inputClasses } = useInputStyles();
  const usernameInputElementId = 'username';
  const { formatMessage } = useIntl();
  const firstNameLabel = formatMessage({ id: 'registerPage.label.firstName' });
  const lastNameLabel = formatMessage({ id: 'registerPage.label.lastName' });
  const registerNewUser = event => {
    event.preventDefault();
    if (!connection || !token) {
      setApiErrorMessageIds(() => {
        return {
          invalidLink: errorMessageIds.failedToRegisterUser,
        };
      });
    } else {
      /*
      Mihai N: is this for real? everywhere else, HCP uses 'HNM' and 'HMH' to differentiate 
      between platforms but this is the only case where it requires 'ed' or 'flight' ?
      no wonder we have an issue alligning the UI, HCP can't even settle on what strings we use to define the platform!!!
       */
      registerNewDistrictAdministrator({
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        username: userDetails.username,
        password: userDetails.password,
        districtId: connection,
        secret: userDetails.orderNumber,
        token,
        platformId: PLATFORM_IDS[platform],
        brandId: null,
      })
        .then(() => {
          const searchParamsString = new URLSearchParams({
            [QUERY_PARAMS.CONNECTION]: connection,
            [QUERY_PARAMS.FROM]: QUERY_PARAMS.REGISTER_USER,
          }).toString();
          history.push({
            pathname: '/',
            search: `?${searchParamsString}`,
          });
        })
        .catch(error => {
          if (error.response.status === HTTP_STATUS_CODES.CONFLICT) {
            setApiErrorMessageIds(() => {
              return {
                usernameExists: errorMessageIds.usernameExists,
              };
            });
          } else {
            setApiErrorMessageIds(() => {
              return {
                invalidLink: errorMessageIds.failedToRegisterUser,
              };
            });
          }
        });
    }
  };

  const getErrors = currentUserDetails => {
    const errors = {};
    const {
      isNameValid: isFirstNameValid,
      nameErrorMessageId: firstNameErrorMessageId,
    } = nameFieldValidator(currentUserDetails.firstName, 'firstName');
    const {
      isNameValid: isLastNameValid,
      nameErrorMessageId: lastNameErrorMessageId,
    } = nameFieldValidator(currentUserDetails.lastName, 'lastName');
    const { isUsernameValid, usernameErrorMessageId } = usernameValidator(
      currentUserDetails.username,
    );
    const { isPasswordValid, passwordErrorMessageId } = passwordValidator(
      userDetails.password,
    );
    if (!currentUserDetails.orderNumber) {
      if (platform === PLATFORMS.FLIGHT) {
        errors.orderNumber = errorMessageIds.referenceNumberRequired;
      } else {
        errors.orderNumber = errorMessageIds.orderNumberRequired;
      }
    }

    if (!isFirstNameValid) {
      errors.firstName = firstNameErrorMessageId;
    }

    if (!isLastNameValid) {
      errors.lastName = lastNameErrorMessageId;
    }

    if (!isUsernameValid) {
      errors.username = usernameErrorMessageId;
    }
    if (!isPasswordValid) {
      errors.password = passwordErrorMessageId;
    }
    if (userDetails.password === userDetails.username) {
      errors.password = errorMessageIds.usernameMatchesPassword;
    }
    if (userDetails.password !== userDetails.verifyPassword) {
      errors.verifyPassword = errorMessageIds.passwordsDoNotMatch;
    }
    if (!userDetails.verifyPassword) {
      errors.verifyPassword = errorMessageIds.verifyPasswordIsRequired;
    }
    return errors;
  };

  const handleChange = event => {
    event.persist();
    const keyOfValueToUpdate = event.target.id;
    const { value } = event.target;
    setUserDetails(currentUserDetails => {
      return {
        ...currentUserDetails,
        [keyOfValueToUpdate]: value,
      };
    });
    setTouched(currentTouched => {
      return {
        ...currentTouched,
        [keyOfValueToUpdate]: true,
      };
    });
    if (event.target.id === usernameInputElementId) {
      setApiErrorMessageIds(currentApiErrors => {
        return {
          ...currentApiErrors,
          usernameExists: undefined,
        };
      });
    }
  };

  const handleBlur = event => {
    event.persist();
    const keyOfValueToUpdate = event.target.id;
    setTouched(currentTouched => {
      return {
        ...currentTouched,
        [keyOfValueToUpdate]: true,
      };
    });
  };

  const errors = getErrors(userDetails);
  const isValid = Object.keys(errors).length === 0;

  return (
    <TenantPickerLayout>
      <header>
        <Logo />
        {apiErrorMessageIds.invalidLink && (
          <HcpAlert
            size={ALERT_SIZE.SMALL}
            severity={SEVERITY.ERROR}
            title={formatMessage({
              id: 'tenantPicker.errorTitle',
            })}
            subtitle={formatMessage({
              id: apiErrorMessageIds.invalidLink,
            })}
            action={null}
            className={classes.error}
          />
        )}
        <div id="title-container" className={inputClasses.title}>
          <Typography component="h1" variant="h3">
            <FormattedMessage id="tenantPicker.register.title" />
          </Typography>
          <RequiredFieldsNote />
        </div>
      </header>
      <main>
        <form className={classes.form}>
          <OrderNumber
            orderNumber={userDetails.orderNumber}
            error={errors.orderNumber}
            isTouched={touched.orderNumber}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <OrderNumberLink />
          <NameField
            name={userDetails.firstName}
            nameLabel={firstNameLabel}
            errorMessageId={touched.firstName && errors.firstName}
            handleChange={handleChange}
            handleBlur={handleBlur}
            elementId="firstName"
          />
          <NameField
            name={userDetails.lastName}
            nameLabel={lastNameLabel}
            errorMessageId={touched.lastName && errors.lastName}
            handleChange={handleChange}
            handleBlur={handleBlur}
            elementId="lastName"
          />
          <Username
            username={userDetails.username}
            errorMessageId={
              touched.username &&
              (errors.username || apiErrorMessageIds.usernameExists)
            }
            handleChange={handleChange}
            handleBlur={handleBlur}
            elementId={usernameInputElementId}
          />
          <Password
            password={userDetails.password}
            errorMessageId={touched.password && errors.password}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <Typography variant="body2">
            <FormattedMessage id="tenantPicker.passwordRequirements" />
          </Typography>
          <VerifyPassword
            password={userDetails.verifyPassword}
            errorMessageId={touched.verifyPassword && errors.verifyPassword}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <CreateAccountButton
            onClick={registerNewUser}
            isDisabled={
              !isValid || apiErrorMessageIds.usernameExists !== undefined
            }
          />
        </form>
      </main>
    </TenantPickerLayout>
  );
};

export default RegisterUserPage;
