/**
 * Handles email verification logic.
 *
 * @param {string} email - The email to verify.
 * @param {string} pid - The district ID.
 * @param {Array} domainList - List of allowed domains.
 * @param {Function} setEmailError - Function to update email error state.
 * @param {Function} setIsValidEmail - Function to update email validation state.
 * @param {Function} setIsVerifyDisabled - Function to disable/enable the verify button.
 * @param {Function} setEmailVerified - Function to update email verified state.
 * @param {Function} setShowCaptcha - Function to toggle Captcha display.
 */

export const resetPageState = (
  setEmail,
  setEmailError,
  setEmailVerified,
  setShowCaptcha,
  setIsValidEmail,
  setIsVerifyDisabled,
  setIsSuccess,
) => {
  setEmail('');
  setEmailError('');
  setEmailVerified(false);
  setShowCaptcha(false);
  setIsValidEmail(false);
  setIsVerifyDisabled(false);
  setIsSuccess(false);
};

export const handleVerifyButton = (
  email,
  pid,
  domainList,
  setEmailError,
  setIsValidEmail,
  setIsVerifyDisabled,
  setEmailVerified,
  setShowCaptcha,
) => {
  if (!email.trim()) {
    setEmailError('Email is required');
    setIsValidEmail(false);
    setIsVerifyDisabled(true);
    return;
  }

  const validateEmail = inputEmail => /\S+@\S+\.\S+/.test(inputEmail);

  if (!validateEmail(email)) {
    setEmailError('Invalid email format');
    setIsValidEmail(false);
    setIsVerifyDisabled(true);
    return;
  }

  const emailParts = email.split('@');
  if (emailParts.length !== 2) {
    setEmailError('Invalid email format');
    setIsValidEmail(false);
    setIsVerifyDisabled(true);
    return;
  }

  setEmailError('');
  setIsValidEmail(true);
  setIsVerifyDisabled(true);
  setEmailVerified(true);
  setShowCaptcha(true);
};
