import { PLATFORMS } from '../../../../environments/globalConstants';

export const ISSUER_URL = 'https://aqueductpreview.hmhco.com';

/**
 * HIP LEGACY
 */
export const LEGACY_ED_CLIENT_ID =
  '152ced50-1369-4b19-8b26-8f3d5d9bfd6a.hmhco.com';

/**
 * IDS AUTH LEGACY
 */
export const LEGACY_FLIGHT_CLIENT_ID = '80B4EFE0-B723-46AC-A46A-C75037B44F86';

/**
 * MAIN CLIENT IDS
 */
export const CLIENT_IDS = {
  [PLATFORMS.ED]: '8615f365-7cad-4dc7-a9bb-18f3cd99fb25',
  [PLATFORMS.FLIGHT]: '2cc7842e-ed1b-401e-b203-c2756a26d795',
  [PLATFORMS.NWEA]: 'ee5fccd2-365b-4478-a9e6-8b64802725ad',
  [PLATFORMS.SUPPORT_TOOL]: '8615f365-7cad-4dc7-a9bb-18f3cd99fb25',
  [PLATFORMS.FLIGHT_CONTENT_SHARE]: '8615f365-7cad-4dc7-a9bb-18f3cd99fb25',
};

/**
 * LOCAL IDS AUTH LEGACY
 */
export const LOCAL_LEGACY_FLIGHT_CLIENT_ID =
  '63f5d8fe-2a73-4681-82d6-9fe4d45a6472';

/**
 * LOCAL CLIENT IDS
 */
export const LOCAL_CLIENT_IDS = {
  [PLATFORMS.ED]: '219cadbb-0827-4f1c-8e40-8129f778c4b3',
  [PLATFORMS.FLIGHT]: '4ca1105d-63f1-4139-ab5a-ca7762cd239c',
  [PLATFORMS.NWEA]: 'da749a16-787d-4761-8166-6463dd008dc5',
  [PLATFORMS.SUPPORT_TOOL]: '219cadbb-0827-4f1c-8e40-8129f778c4b3',
  [PLATFORMS.FLIGHT_CONTENT_SHARE]: '219cadbb-0827-4f1c-8e40-8129f778c4b3',
};
