import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import Svg from '@hmhco/svg/src/Svg';
import addTitleToSvg from '@hmhco/svg/src/utils/svgModifier';
import hmhLogo from '@ed/baseline/VAL/hmh/HMHEd_c_158x32.svg';
import { usePlatform, PLATFORMS } from '@hmhco/platform-helper';
import heinemennLogo from '@ed/baseline/images/login-illustrations/hnm-logo.svg';
import nweaLogo from '@ed/baseline/images/login-illustrations/nwea-logo.svg';
import useStyles from './Logo.styles';

const Logo = () => {
  const platform = usePlatform();
  const { cx, classes } = useStyles();
  const { formatMessage } = useIntl();

  const svg = useMemo(() => {
    switch (platform) {
      case PLATFORMS.NWEA:
        return addTitleToSvg(nweaLogo, formatMessage({ id: 'nweaLogo' }));
      case PLATFORMS.FLIGHT:
        return addTitleToSvg(
          heinemennLogo,
          formatMessage({ id: 'heinemannFlightLogo' }),
        );
      case PLATFORMS.ED:
      default:
        return addTitleToSvg(hmhLogo, formatMessage({ id: 'hmhEdLogo' }));
    }
  }, [platform, formatMessage]);

  return (
    <div className={classes.root}>
      <Svg
        key={platform}
        className={cx(classes.logo, {
          [classes.nweaLogo]: platform === PLATFORMS.NWEA,
          [classes.heinemennLogo]: platform === PLATFORMS.FLIGHT,
          [classes.hmhLogo]: platform === PLATFORMS.ED,
        })}
        svg={svg}
      />
    </div>
  );
};

export default Logo;
