import React from 'react';
import { useDocumentHelper } from '@hmhco/document-helper';
import { useIntl, FormattedMessage } from 'react-intl';
import { usePlatform } from '@hmhco/platform-helper';
import { Typography } from '@mui/material';
import TenantPickerLayout from '../../Layout/TenantPickerLayout';
import Logo from '../../Logo/Logo';
import useStyles from './LoggedOutPage.styles';
import { useCleanLoginMethod } from '../../../../hooks/useCleanLoginMethod';

const LoggedOutPage = () => {
  const { formatMessage } = useIntl();
  const { classes } = useStyles();
  const platform = usePlatform();

  const ariaTitle = formatMessage({ id: 'loggedOut.title.aria' }, { platform });
  useDocumentHelper(ariaTitle);

  /**
   * If the user is redirected to logged out page,
   * we need to remove the login method from localStorage
   */
  useCleanLoginMethod();

  return (
    <TenantPickerLayout>
      <header>
        <Logo />
        <div className={classes.heading}>
          <Typography component="h1" variant="h3" aria-label={ariaTitle}>
            <FormattedMessage id="loggedOut.title" />
          </Typography>
        </div>
      </header>
      <main className={classes.main}>
        <Typography>
          <FormattedMessage id="loggedOut.description" />
        </Typography>
      </main>
    </TenantPickerLayout>
  );
};

export default LoggedOutPage;
