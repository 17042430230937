import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'RetryComponent' })(theme => ({
  retryLink: {
    marginTop: 'var(--ebl-s3)',
  },
  retryButton: {
    maxWidth: 'fit-content',
    margin: 'var(--ebl-s3) 0 0 0',
  },
  successAlert: {
    padding: 'var(--ebl-s4) var(--ebl-s3)',
    marginBottom: 'var(--ebl-s3)',
  },
  successAlertIcon: {
    marginRight: '0 !important',
  },
  emailBreak: {
    display: 'inline',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));
