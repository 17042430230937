import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'Logo' })(() => ({
  root: {
    paddingBottom: 'var(--ebl-s3)',
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    width: '158px',
    height: '74px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      height: '100%',
      width: '100%',
    },
  },
  nweaLogo: {
    width: '137.5px',
  },
  heinemennLogo: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  hmhLogo: {},
}));
