import { identifyPlatform } from './utils/identifyPlatform';
import { getIdentifiedPlatformConfig } from './utils/getIdentifiedPlatformConfig';
import { getIdentifiedPlatformClientId } from './utils/getIdentifiedPlatformClientId';
import { getLegacyFlightClientId } from './utils/getLegacyFlightClientId';
import {
  CLIENT_IDS,
  LOCAL_CLIENT_IDS,
  LOCAL_LEGACY_FLIGHT_CLIENT_ID,
  LEGACY_FLIGHT_CLIENT_ID,
  LEGACY_ED_CLIENT_ID,
  ISSUER_URL,
} from './constants';

/**
 * ! SHOULD BE USED ONLY BY HCP LOGIN UI
 * ! CONSULT WITH ENABLED TEAM BEFORE USING IT ANYWHERE ELSE
 */
export {
  identifyPlatform,
  getIdentifiedPlatformConfig,
  getIdentifiedPlatformClientId,
  getLegacyFlightClientId,
  CLIENT_IDS,
  LOCAL_CLIENT_IDS,
  LOCAL_LEGACY_FLIGHT_CLIENT_ID,
  LEGACY_FLIGHT_CLIENT_ID,
  LEGACY_ED_CLIENT_ID,
  ISSUER_URL,
};
