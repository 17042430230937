import { createAxiosCancelable } from '@hmhco/core-network/src/axiosHelpers';
import requestHelper from '@hmhco/core-network/src/utils/requestHelper';
import { getIdentifiedPlatformConfig } from '@hmhco/login-config';

const url = '/user/resetPassword';
const changePasswordApi = (pactConfig = {}) => {
  const { client, cancelToken, isCancel } = createAxiosCancelable({
    includeAuth: false,
  });
  const request = requestHelper({
    client,
    cancelToken,
    isCancel,
    baseURL: getIdentifiedPlatformConfig().authorizationApi,
    pactConfig,
  });
  return {
    changePassword: ({ username, password, token, platformId }) => {
      const payload = {
        username,
        password,
        token,
        platformId,
      };
      return request.post(url, payload);
    },
  };
};

export default changePasswordApi;
