import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, TextField, Button, Box } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { ALERT_SIZE, HcpAlert, SEVERITY } from '@hmhco/hcp-alert';
import { useGetConnectionFromUrl } from '../../../../hooks/getConnectionParam.hook';
import useBootstrapOrgInfo from '../../../../hooks/bootstrapOrgInfo.hook';

import TenantPickerLayout from '../../Layout/TenantPickerLayout';
import Logo from '../../Logo/Logo';
import useStyles from './EdEarlyAccessPage.styles';
import CaptchaComponent from './CaptchaComponent';
import RetrySuccessComponent from './RetrySuccessComponent';
import { fetchDomainList } from '../../../../api/fetchDomainsApi';
import { getCurrentOrg } from '../../../../store/selectors/CountryStateOrg';
import {
  handleVerifyButton,
  resetPageState,
} from '../../../../util/verifyEmailUtilsforEdEarlyAccess';

const EdEarlyAccessPage = () => {
  const { classes } = useStyles();
  const connectionFromUrl = useGetConnectionFromUrl();
  const { formatMessage } = useIntl();

  useBootstrapOrgInfo();
  const [email, setEmail] = useState('');
  const [domainList, setDomainList] = useState([]);
  const [emailError, setEmailError] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isVerifyDisabled, setIsVerifyDisabled] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const district = useSelector(getCurrentOrg);
  const validateEmail = inputEmail => /\S+@\S+\.\S+/.test(inputEmail);

  useEffect(() => {
    const domainApi = fetchDomainList();
    domainApi
      .fetch()
      .then(response => {
        setDomainList(response.data);
      })
      .catch(error =>
        console.error(`Error occurred while loading domain list: ${error}`),
      );
    return () => {
      domainApi.cancel();
    };
  }, []);

  const handleEmailChange = event => {
    const { value } = event.target;
    setEmail(value);
    setShowCaptcha(false);
    setEmailVerified(false);
    setIsVerifyDisabled(false);

    if (!value.trim()) {
      setEmailError(
        formatMessage({
          id: 'edEarlyAccess.errorMessage.emailRequired',
        }),
      );
      setIsValidEmail(false);
    } else if (!validateEmail(value)) {
      setEmailError(
        formatMessage({
          id: 'edEarlyAccess.errorMessage.invalid',
        }),
      );
      setIsValidEmail(false);
    } else {
      setEmailError('');
      setIsValidEmail(true);
    }
  };

  const handleVerify = () => {
    handleVerifyButton(
      email,
      connectionFromUrl,
      domainList,
      setEmailError,
      setIsValidEmail,
      setIsVerifyDisabled,
      setEmailVerified,
      setShowCaptcha,
    );
  };

  const resetState = () => {
    resetPageState(
      setEmail,
      setEmailError,
      setEmailVerified,
      setShowCaptcha,
      setIsValidEmail,
      setIsVerifyDisabled,
      setIsSuccess,
    );
  };

  const handleCaptchaSubmit = () => {
    setIsSuccess(true);
  };

  return (
    <TenantPickerLayout>
      {!isSuccess ? (
        <>
          <header>
            <Logo data-testid="logo" />
            {connectionFromUrl === '' && (
              <div>
                <HcpAlert
                  size={ALERT_SIZE.LARGE}
                  severity={SEVERITY.WARNING}
                  title={formatMessage({
                    id: 'edEarlyAccess.magicLink.errorTitle',
                  })}
                  subtitle={formatMessage({
                    id: 'edEarlyAccess.launch.magicLink.expire.error',
                  })}
                  className={classes.error}
                  action={null}
                />
                <div className={classes.errorContinueButton}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={resetState}
                  >
                    <FormattedMessage id="edEarlyAccess.edEarlyAccess.continueButton" />
                  </Button>
                </div>
              </div>
            )}
            {connectionFromUrl && (
              <div className={classes.heading}>
                <Typography
                  component="h4"
                  variant="h4"
                  fontWeight="bold"
                  data-testid="welcome-heading"
                >
                  <FormattedMessage id="edEarlyAccess.edEarlyAccess.titleText.welcome" />
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="h5"
                  className={classes.subtitleText}
                >
                  <FormattedMessage id="edEarlyAccess.edEarlyAccess.subtitleText" />
                </Typography>
              </div>
            )}
          </header>

          <main>
            {connectionFromUrl && (
              <form className={classes.form}>
                <div className={classes.districtComponent}>
                  <Typography
                    variant="subtitle2"
                    component="h6"
                    className={classes.header}
                  >
                    <FormattedMessage id="edEarlyAccess.edEarlyAccess.districtName" />
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.districtNamePlaceholder}
                  >
                    {district?.name ? district.name : ''}
                  </Typography>
                </div>

                <Box className={classes.emailInputContainer}>
                  <TextField
                    id="email"
                    name="email"
                    label="District Email"
                    value={email}
                    className={classes.emailTextField}
                    placeholder={formatMessage({
                      id: `edEarlyAccess.edEarlyAccess.helperText2`,
                    })}
                    onChange={handleEmailChange}
                    error={Boolean(emailError)}
                    helperText={
                      emailVerified ? (
                        <FormattedMessage id="edEarlyAccess.edEarlyAccess.helperText1" />
                      ) : (
                        emailError
                      )
                    }
                    data-testid="email-input"
                    required
                  />
                  <div className={classes.verifyButtonStyle}>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      className={classes.verifyButton}
                      disabled={!isValidEmail || isVerifyDisabled}
                      onClick={handleVerify}
                      data-testid="verify-button"
                    >
                      <FormattedMessage id="edEarlyAccess.VerifyButton" />
                    </Button>
                  </div>
                </Box>
              </form>
            )}
            {showCaptcha && (
              <CaptchaComponent
                onSubmit={handleCaptchaSubmit}
                data-testid="captcha"
              />
            )}
          </main>
        </>
      ) : (
        <RetrySuccessComponent
          email={email}
          onRetry={resetState}
          data-testid="retry-success"
        />
      )}
    </TenantPickerLayout>
  );
};

export default EdEarlyAccessPage;
