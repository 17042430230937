import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import useStyles from './CaptchaComponent.style';

const CaptchaComponent = ({ onSubmit }) => {
  const { classes } = useStyles();

  return (
    <div>
      <Typography variant="subtitle1" className={classes.captchaSubtitle}>
        <FormattedMessage id="edEarlyAccess.edEarlyAccess.captcha.subheading" />
      </Typography>
      <Box className={classes.submitButtonContainer}>
        <Button
          className={classes.submitButton}
          size="large"
          variant="contained"
          color="primary"
          onClick={onSubmit}
        >
          <FormattedMessage id="captcha.submitButton" />
        </Button>
      </Box>
    </div>
  );
};

CaptchaComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default CaptchaComponent;
