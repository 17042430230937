/* eslint-disable import/prefer-default-export */
/**
Generates a random nonce string.
The nonce is a randomly generated string that is creating unique value for a digital signature.
It is generated using random number generation to ensure
unpredictability and resistance against manipulation or prediction.
@returns {string} A randomly generated nonce.
*/
export function randomNonce() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + s4() + s4() + s4();
}
