/* eslint-disable import/prefer-default-export */
import logErrorWithContext from '@hmhco/client-monitoring/src/context/logErrorWithContext';

export function tryDecodeUrlParam(variable, key) {
  try {
    return decodeURIComponent(variable);
  } catch (error) {
    logErrorWithContext('Failed login decoding', [
      { key: 'decodeKey', value: key },
      { key: 'attemptedDecodeValue', value: variable },
    ]);
    return '';
  }
}
