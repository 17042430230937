import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'Error' })(theme => ({
  errorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(10),
    '@media (max-width:1260px)': {
      flexDirection: 'column',
    },
  },
  heading: {
    fontSize: '88px',
    color: theme.palette.primary.main,
    '@media (max-width:550px)': {
      fontSize: '48px',
    },
  },
  heading2: {
    fontSize: '44px',
    lineHeight: '56px',
    margin: '10px 0',
    '@media (max-width:550px)': {
      fontSize: '26px',
    },
  },
  content: {
    fontSize: '24px',
    lineHeight: '32px',
    maxWidth: '500px',
    '@media (max-width:550px)': {
      fontSize: '16px',
      lineHeight: '16px',
    },
  },
  imgContainer: {
    width: 'auto',
  },
  errorImg: {
    maxWidth: '711px',
    width: '100%',
  },
}));
