import { PLATFORMS } from '@hmhco/platform-helper';
import { SEVERITY } from '@hmhco/hcp-alert';

// eslint-disable-next-line import/prefer-default-export
export const QUERY_PARAMS = {
  FROM: 'from',
  CONNECTION: 'connection',
  PASSWORD_RESET: 'passwordReset',
  REGISTER_USER: 'registerUser',
  TOKEN: 'token',
};

export const HTTP_STATUS_CODES = {
  SUCCESS: 200,
  UNAUTHORIZED: 401,
  CONFLICT: 409,
  SERVICE_UNAVAILABLE: 503,
};

export const AUTO_COMPLETE = {
  GIVEN_NAME: 'given-name',
  FAMILY_NAME: 'family-name',
  USERNAME: 'username',
  PASSWORD: 'current-password',
  NEW_PASSWORD: 'new-password',
  ONE_TIME_CODE: 'one-time-code',
};

export const PLATFORM_IDS = {
  [PLATFORMS.ED]: 'ed',
  [PLATFORMS.FLIGHT]: 'flight',
};

/* This mocking will be replaced by an API for initializeURI */
export const INITIALIZE_URI_MOCKING = {
  ssoAccount: false,
  oktaEmbeddedWidget: true,
};

export const USER_ALERTS = {
  FAILED_PID: {
    titleId: 'login.form.error.title',
    subtitleId: 'login.form.error.subtitle',
    severity: SEVERITY.ERROR,
  },
};

export const FIELD_RELATED_USER_ERRORS = {
  FOUND_FORM_ERRORS: 'foundFormErrors',
  UNABLE_SIGN_IN: 'unableSignIn',
};

export const FIELD_RELATED_USER_ERROR_TEXTS = {
  [FIELD_RELATED_USER_ERRORS.FOUND_FORM_ERRORS]:
    'We found some errors. Please review the form and make corrections.',
  [FIELD_RELATED_USER_ERRORS.UNABLE_SIGN_IN]: 'Unable to sign in',
};

export const LOGIN_FLOWS = {
  OKTA: 'OktaFlow',
  INTEGRATED: 'IntegratedFlow',
};

export const LOGIN_METHOD_KEY = 'com.hmhco.login.method';
export const LOGIN_METHODS = {
  SSO: 'sso',
};

export const SCOPE = 'openid profile';
export const RESPONSE_TYPE = 'id_token token';
