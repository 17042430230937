import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteCookie,
  getCookie,
} from '@hmhco/cookie-accessors/src/cookieHelper';
import { setLoginFailed } from '../store/actions/UsernameAndPasswordActions';
import { getLoginStep } from '../store/selectors/TenantPickerState';

export const FAILED_LOGIN_COOKIE = 'loginFailed';

function useCheckLoginCookies() {
  const dispatch = useDispatch();
  const currentStep = useSelector(getLoginStep);

  useEffect(() => {
    const loginFailed = getCookie(FAILED_LOGIN_COOKIE);
    if (loginFailed && currentStep === 2) {
      dispatch(setLoginFailed());
      deleteCookie(FAILED_LOGIN_COOKIE);
    }
  }, [currentStep]);
}

export default useCheckLoginCookies;
