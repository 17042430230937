export enum CapabilitiesKeyType {
  manageUser = 'add_user_functions',
  manageLicense = 'manage_licensing',
  performBulkRostering = 'perform_bulk_rostering',
  performManualRostering = 'perform_manual_rostering',
  reporting = 'view_reporting',
  viewProduct = 'use_product_bundle',
  manageSchool = 'manage_school',
  worksForSchool = 'works_for_school',
  manageCapabilities = 'manage_capabilities',
  manageClassroom = 'manage_classroom',
  attendSchool = 'attend_school',
  teachClass = 'teach_class',
  attendClass = 'attend_class',
  manageDistrict = 'manage_district',
  worksForDistrict = 'works_for_district',
  manageHSPNWEABid = 'manage_hsp_nwea_bid',
  manageHSPBundleProductMetadata = 'manage_hsp_bundle_product_metadata',
  viewHSPBundleProductMetadata = 'view_hsp_bundle_product_metadata',
  manageHSPCapabilities = 'manage_hsp_capabilities',
  manageHSPFeatureWorkInProgress = 'manage_hsp_feature_work_in_progress',
  editHspBundleProductMetadata = 'edit_hsp_bundle_product_metadata',
}

export enum CapabilitiesGroupType {
  private = 'private',
  district = 'district',
  school = 'school',
  class = 'class',
  individual = 'individual',
  institution = 'institution',
}
