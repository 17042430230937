import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'ForgotPasswordPage' })(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& > *': {
      marginBottom: 'var(--ebl-s2) !important',
    },
  },
  helpText: {
    marginBottom: '0px !important',
  },
  studentHelpText: {
    width: '80%',
  },
}));
