/* eslint-disable import/prefer-default-export */
import { Authorize } from '@hmh/eds-login-js';
import logErrorWithContext from '@hmhco/client-monitoring/src/context/logErrorWithContext';
import {
  ISSUER_URL,
  getIdentifiedPlatformConfig,
  getLegacyFlightClientId,
} from '@hmhco/login-config';
import { getSignedInUrl } from '../util/getSignedInUrl';
import {
  createForm,
  setAttributes,
  setPassword,
  setUsername,
  submitForm,
} from './formHelpers';

/*
 * Login is using a form submission because "Architecture" want login to do a browser redirect rather
 * respond with a JSON payload to reduce the likelyhood of man in the middle. This means that the UI
 * code ends up being convoluted and difficult to PACT test as the JSDOM environment does not support
 * native form submissions. This also greatly increases the risk of MI's (3 of the last UI caused MI's
 * were in login related code) as the PACT tests are not using the same API call that is made by real
 * users. Our PACT workaround is to use the formHelpers below to monitor what is being set on the form
 * and then replicating this same data in an axios call that's made in the PACT test.
 */
export async function loginHnm({ pid, username, password }) {
  try {
    const authorize = new Authorize(
      getLegacyFlightClientId(),
      ISSUER_URL,
      getIdentifiedPlatformConfig().edsInitUrl,
      {
        connection: pid,
        password,
        targetLinkUri: getSignedInUrl(),
        username,
      },
    );
    authorize.getThirdPartyLoginUri().then(loginInitUri => {
      const form = createForm();
      const formAction = `${loginInitUri}&connection=${pid}`;
      setAttributes(form, {
        method: 'post',
        url: formAction,
        targetLinkUri: getSignedInUrl(),
        encoding: 'application/x-www-form-urlencoded',
      });
      setUsername(form, username);
      setPassword(form, password);
      submitForm(form);
    });
    sessionStorage.setItem(
      'accountConfiguration',
      JSON.stringify({ defaultAccountType: 'institutional' }),
    );
  } catch (error) {
    logErrorWithContext('Login app - failed to log in', [
      { key: 'errorMessage', value: error },
    ]);
  }
}
