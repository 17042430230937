import { createBaseThemeSettings, createPalette } from '@hmhco/base-theme';
import { createTheme } from '@mui/material/styles';
import merge from 'lodash/merge';
import colours from './nweaThemeColours.json';

const nweaPaletteOverrides = {
  // this extra color needed for Alert component neutal variant
  neutral: {
    main: '#ffffff',
    light: '#ffffff',
  },
};

export default createTheme(
  createBaseThemeSettings({
    palette: merge(createPalette(colours), nweaPaletteOverrides),
  }),
);
