/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react';
import { removeLocalStorageValue } from '../storageHelpers/localStorageAccessors';
import { LOGIN_METHOD_KEY } from '../constants/constants';

export function useCleanLoginMethod() {
  useEffect(() => {
    removeLocalStorageValue(LOGIN_METHOD_KEY);
  }, []);
}
