/* eslint-disable import/prefer-default-export */
import {
  identifyPlatform,
  getIdentifiedPlatformConfig,
} from '@hmhco/login-config';
import { PLATFORMS } from '@hmhco/platform-helper';

/**
 * @deprecated this function is deprecated when logged-in url is available for Flight
 * @returns
 */
export function getSignedInUrl() {
  const platform = identifyPlatform();
  const config = getIdentifiedPlatformConfig();

  if (platform === PLATFORMS.FLIGHT) {
    return config.ampSignedInURL;
  }

  return config.loggedInUrl;
}
