import React from 'react';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import LinkToNewTab from '@hmhco/link-to-new-tab/src/LinkToNewTab';
import { PLATFORMS, usePlatform } from '@hmhco/platform-helper';
import { getIdentifiedPlatformConfig } from '@hmhco/login-config';
import useStyles from './Footer.styles';

const Footer = () => {
  const platform = usePlatform();
  const { cx, classes } = useStyles();

  const {
    helpUrl,
    privacyPolicyUrl,
    termsAndConditionsUrl,
  } = getIdentifiedPlatformConfig();

  return (
    <footer className={classes.root}>
      <Typography variant="body2" className={classes.paragraph}>
        <FormattedMessage id="helpLink.aria">
          {ariaLabel => (
            <LinkToNewTab
              className={cx(classes.link, {
                [classes.linkHeinemann]: platform === PLATFORMS.FLIGHT,
              })}
              href={helpUrl}
              aria-label={ariaLabel}
            >
              <FormattedMessage id="helpLink" />
            </LinkToNewTab>
          )}
        </FormattedMessage>
      </Typography>
      <Typography variant="body2" className={classes.paragraph}>
        <FormattedMessage id="termsAndConditions.aria">
          {ariaLabel => (
            <LinkToNewTab
              className={cx(classes.link, {
                [classes.linkHeinemann]: platform === PLATFORMS.FLIGHT,
              })}
              href={privacyPolicyUrl}
              aria-label={ariaLabel}
            >
              <FormattedMessage id="termsAndConditions" />
            </LinkToNewTab>
          )}
        </FormattedMessage>
      </Typography>
      <Typography variant="body2" className={classes.paragraph}>
        <FormattedMessage id="privacyPolicy.aria">
          {ariaLabel => (
            <LinkToNewTab
              className={cx(classes.link, {
                [classes.linkHeinemann]: platform === PLATFORMS.FLIGHT,
              })}
              href={termsAndConditionsUrl}
              aria-label={ariaLabel}
            >
              <FormattedMessage id="privacyPolicy" />
            </LinkToNewTab>
          )}
        </FormattedMessage>
      </Typography>
    </footer>
  );
};

export default Footer;
