import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { ALERT_SIZE, HcpAlert, SEVERITY } from '@hmhco/hcp-alert';
import PropTypes from 'prop-types';
import { Typography, Button } from '@mui/material';
import Logo from '../../Logo/Logo';
import useStyles from './RetrySuccessComponent.style';

const RetrySuccessComponent = ({ email, onRetry }) => {
  const { classes } = useStyles();
  const [countdown, setCountdown] = useState(5);
  const [showRetry, setShowRetry] = useState(false);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
    setShowRetry(true);
    return undefined; // to resolve eslint error
  }, [countdown]);

  return (
    <>
      <header>
        <Logo />
      </header>
      <HcpAlert
        className={classes.successAlert}
        size={ALERT_SIZE.LARGE}
        severity={SEVERITY.SUCCESS}
        title={
          <FormattedMessage
            id="edEarlyAccess.magicLink.successTitle"
            values={{
              email: <strong className={classes.emailBreak}>{email}</strong>,
            }}
          />
        }
        subtitle={
          <FormattedMessage id="edEarlyAccess.magicLink.successTitle.subTitle" />
        }
        action={null}
      />
      <Typography variant="subtitle1">
        <FormattedMessage id="edEarlyAccess.edEarlyAccess.didntReceiveLink" />
      </Typography>
      {showRetry ? (
        <Button
          size="large"
          role="button"
          variant="outlined"
          color="primary"
          className={classes.retryButton}
          onClick={onRetry}
        >
          <FormattedMessage id="edEarlyAccess.retryButton" />
        </Button>
      ) : (
        <Typography variant="subtitle1" className={classes.retryLink}>
          <FormattedMessage
            id="edEarlyAccess.retryMessage"
            values={{ countdown: String(countdown).padStart(2, '0') }}
          />
        </Typography>
      )}
    </>
  );
};

RetrySuccessComponent.propTypes = {
  email: PropTypes.string.isRequired,
  onRetry: PropTypes.func.isRequired,
};

export default RetrySuccessComponent;
