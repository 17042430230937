import { createAndDisplayOktaWidget } from './createAndDisplayOktaWidget';
import { createObserversToUpdateWidget } from './createObserversToUpdateWidget';
import { isOktaAvailable } from './utils';
import { oktaReady } from '../../../store/actions/TenantPickerActions';

export const initializeOktaForm = (
  isVisible,
  oktaInfo,
  widgetRef,
  formatMessage,
  dispatch,
) => {
  if (!isOktaAvailable(isVisible, oktaInfo, widgetRef)) {
    return () => {};
  }

  const oktaForm = {
    oktaWidget: null,
    observers: null,
  };

  (async () => {
    oktaForm.oktaWidget = await createAndDisplayOktaWidget(oktaInfo, widgetRef);

    if (!oktaForm.oktaWidget) {
      return;
    }

    oktaForm.oktaWidget.on('ready', () => {
      dispatch(oktaReady(true));
    });

    oktaForm.observers = createObserversToUpdateWidget(
      widgetRef,
      formatMessage,
    );
  })();

  return () => {
    if (oktaForm.oktaWidget) {
      oktaForm.oktaWidget.remove();
    }
    if (oktaForm.observers) {
      oktaForm.observers.containerObserver?.disconnect();
      oktaForm.observers.fieldObservers.forEach(fieldObserver =>
        fieldObserver.disconnect(),
      );
      oktaForm.observers.alertObservers.forEach(alertObserver =>
        alertObserver.disconnect(),
      );
    }
  };
};

export default initializeOktaForm;
